import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap';
import { PostTypesSelectorComponent } from './post-types-selector/post-types-selector.component';
import { PostTypesListComponent } from './post-types-list/post-types-list.component';

@NgModule({
	imports: [
		CommonModule,
		BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        RouterModule
	],
	exports: [
        PostTypesSelectorComponent,
        PostTypesListComponent
	],
	declarations: [PostTypesSelectorComponent, PostTypesListComponent]
})
export class PostTypesModule { }
