import { Router } from '@angular/router';
import { PostsService } from 'app/posts/posts.service';
import { Component, OnInit, NgZone, Input, HostListener } from '@angular/core';
import { LatLng, LatLngLiteral, AgmMarker } from "@agm/core";
import { SitesService } from "app/core/services/sites.service";
import { AccountService } from "app/core/services/account.service";
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

declare var google: any;

@Component({
    selector: 'app-posts',
    templateUrl: './posts.component.html',
    styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {

    longitude: number;
    latitude: number;
    zoom: number;

    paths = [];

    fillColor: any;

    post = {
        mappedPosts: []
    }

    neighborhoodsDetails = [];
    siteKey: any;
    currentSite: any;
    neighborhoods: any;    
    firstGeolocation = true;

    groupObj = {count:0};	
    subscriptionObj = {status:''};
    
    communityBoughtObj = {count: 0};
    communityObj = {status:''};
    communityGroupObj = {count: 0};
    message:string;

    scrollMapIconPath;
    $key;

    receiveMessage($event) {                     
        var loc = {lat: 0.0, lng: 0.0, itemHeight: 0, createdAt: 0, mapIconPath: '', $key: '', scaledSize: {height: 40, width: 40}};        
        loc.lat = $event.lat;      
        loc.lng = $event.lng;
        loc.createdAt = $event.createdAt;        
        loc.mapIconPath = $event.mapIconPath;  
        loc.$key = $event.$key;

        this.latitude = loc.lat;
        this.longitude = loc.lng;
        this.scrollMapIconPath = loc.mapIconPath;        
        this.$key = loc.$key;
    }    
    
    constructor(private sitesService: SitesService,
        private router: Router,
        private postsService: PostsService,
        private accountService: AccountService,
        private titleService: Title) {
            titleService.setTitle('Wissle Alerts');
            this.postsService.showOnMap.subscribe(post => {
                this.post.mappedPosts.push(post);                
                if (this.firstGeolocation)
                {
                    this.firstGeolocation = false;
                    this.longitude = post.longitude;
                    this.latitude = post.latitude;
                }                                
            },error=>{
                //console.log(error);
            },
            );        

            this.neighborhoods = this.sitesService.getNeighorhoods()
                .snapshotChanges().pipe(map(snapshots => snapshots.map(snapshot => {
                return { $key: snapshot.key }
                })));

            //console.log(this.neighborhoods)
            this.neighborhoods.subscribe(a => {
                //console.log('s', JSON.stringify(a))
                this.getNeighborhoodsDetails(a);
                
            })

            this.sitesService.getSiteMap().subscribe(siteMap => {
                if (siteMap) {
                    this.latitude = siteMap.latitude;
                    this.longitude = siteMap.longitude;
                    this.zoom = siteMap.zoom;
                    this.fillColor = siteMap.color;
                    this.paths = siteMap.paths;                    
                }else{  // default to user's home address
              
                    this.accountService.getUserDetails()
                    .valueChanges()
                    .subscribe((details: any) => {		              						                        
                        if (details.homeLocation != null) {                            
                            this.latitude = details.homeLocation.latitude;
                            this.longitude = details.homeLocation.longitude;
                        } 
                        else
                        {
                            this.latitude = 34.04111992126407;
                            this.longitude = -118.55014559976576;
                        }                       
                    });

                    this.zoom = 14;
                    this.fillColor = "#2196f3";
                    this.scrollMapIconPath = '/assets/svg/crime.svg';
                    this.paths = [{lat: 
                        this.latitude,
                        lng: 
                        this.longitude
                        },{lat: 
                            this.latitude,
                            lng: 
                            this.longitude
                            }];
                }    
            },err=>{
                //console.log(err)
            });
                                            
            let promises = Promise.all(
                [
                    this.sitesService.getUserStatus2(this.accountService.currentUser.uid, this.subscriptionObj, this.groupObj),
                    this.sitesService.getUserCommunityInfo2(this.accountService.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj)
                ]
            );   
            
        if (this.postsService.getFeed().length === 0) {            
            //this.postsService.getPastAlerts();
        }
    }

    ngOnInit() {
        this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);})
    }

    markerClick(post) {        
        this.router.navigate(['/neighborhood/alerts',{ outlets: { modal: ['view', post.$key] } }]);
    }

    IsActiveMarker() {        
        this.router.navigate(['/neighborhood/alerts',{ outlets: { modal: ['view', this.$key] } }]);
    }

    getNeighborhoodsDetails(a){

		a.forEach(element => {
			
			if (element.$key) {
			
				this.sitesService.getGroup(element.$key)
					.valueChanges()
					.subscribe((site: any) => {
							
						//console.log("name", site.name)
						if(site && site.name){
							
							var noImage = false;
							if (site.backgroundImage.downloadURL == 'assets/images/community-mockup.jpg')					
								noImage = true;

							var isGroup = false;
							if (site.groupType == 'essential' || site.groupType == 'plus' || site.groupType == 'premium')
								isGroup = true;
							this.neighborhoodsDetails.push({"address": site.address, "noImage":noImage, "isGroup":isGroup, "backgroundImage":site.backgroundImage.downloadURL[0], "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
							//this.neighborhoodsDetails.push({"address": site.address,  "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
							//console.log(this.neighborhoodsDetails)
							this.accountService.currentSiteKey.subscribe(siteKey => {
								//console.log("siteKey", siteKey)
								this.siteKey = siteKey;
						});
						//alert("aaa")
						const filteredArr = this.neighborhoodsDetails.reduce((acc, current) => {
							const x = acc.find(item => item.siteKey === current.siteKey);
							if (!x) {
								return acc.concat([current]);
							} else {
								return acc;
							}
							}, []);
							this.neighborhoodsDetails = filteredArr;
							//console.log("this.neighborhoodsDetails", this.neighborhoodsDetails)
							//this.neighborhoodsevents.emit(filteredArr);										
														
							this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == this.siteKey);
							//console.log(this.currentSite)
							//this.elementRef.nativeElement.innerHTML = site.name;
						}
						
					});
			} else {
				
				this.accountService.currentSiteKey.subscribe(siteKey => {
					
					this.sitesService.getGroup(siteKey)
						.valueChanges()
						.subscribe((site: any) => {
							
							//console.log("name", site.name)
							if(site && site.name){											
								this.neighborhoodsDetails.push({"site": site, "siteKey":element.$key});
								//this.elementRef.nativeElement.innerHTML = site.name;											
							}
							
						});
				});
			}
		});

		//console.log(this.neighborhoodsDetails)
		
	}

    selectNeighborhood(key) {
		if (key != 'default key'){
			if (this.neighborhoodsDetails.length > 0){
				this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == key);				
			}												
		}				
	}
}
