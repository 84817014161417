import { Component, OnInit } from '@angular/core';
import { ContactService } from "app/core/services/contact.service";


@Component({
    selector: 'app-contact-questions',
    templateUrl: './contact-questions.component.html',
    styleUrls: ['./contact-questions.component.css']
})
export class ContactQuestionsComponent implements OnInit {

    email = '';
    name = '';
    description = '';
    phone = ''
    subject = '';

    constructor(private contactService: ContactService) { }

    ngOnInit() {
    }

    submit() {
        this.contactService.question(this.email, this.name, this.phone, this.description, this.subject)
            .then(() => {
                this.email = '';
                this.name = '';
                this.description = '';
                this.phone = '';
                this.subject = '';
            })
    }

}
