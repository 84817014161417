import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-discussion-board',
  templateUrl: './tutorial-discussion-board.component.html',
  styleUrls: ['./tutorial-discussion-board.component.css']
})
export class TutorialDiscussionBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}