import { PostItemModalComponent } from './post-item-modal/post-item-modal.component';
import { PostItemComponent } from './post-item/post-item.component';
import { PostsComponent } from './posts/posts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostItemCreateComponent } from "app/posts/post-item-create/post-item-create.component";
import { AuthGuard } from "app/app.guard";

const routes: Routes = [
	{
		path: 'neighborhood/alerts', component: PostsComponent, canActivate: [AuthGuard],
		children: [
			{
				path: 'view/:key',
				component: PostItemModalComponent,
				outlet: 'modal'
			},
			{
				path: 'create/:key',
				component: PostItemCreateComponent,
				outlet: 'modal'
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PostsRoutingModule { }
