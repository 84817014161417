import { Component, OnInit } from '@angular/core';
import { ContactService } from "app/core/services/contact.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-contact-help',
    templateUrl: './contact-help.component.html',
    styleUrls: ['./contact-help.component.css']
})
export class ContactHelpComponent implements OnInit {

    email = '';
    name = '';
    description = '';
    phone = ''

    constructor(private contactService: ContactService, private router: Router) { }

    ngOnInit() {
    }

    submit() {
        this.contactService.help(this.email, this.name, this.phone, this.description)
            .then(() => {
                this.email = '';
                this.name = '';
                this.description = '';
                this.phone = '';
                this.router.navigate(['/marketing/contact/thank-you']);
            })
    }
}
