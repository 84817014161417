import { CommentItemCreateComponent } from './../../comments/comment-item-create/comment-item-create.component';
import { ActivatedRoute } from '@angular/router';
import { CommentItemListComponent } from './../../comments/comment-item-list/comment-item-list.component';
import { PostItemComponent } from './../post-item/post-item.component';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-post-item-modal',
    templateUrl: './post-item-modal.component.html',
    styleUrls: ['./post-item-modal.component.css']
})
export class PostItemModalComponent implements OnInit {

    @ViewChild('postItem') postItem: PostItemComponent;
    @ViewChild('comments') comments: CommentItemListComponent;

    @ViewChild('commentCreate') commentCreate: CommentItemCreateComponent

    alert = 'post';
    source = 'Alert';  // helps load the comments from latest to first

    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.postItem.key = params.key;            
            this.comments.parentKey = params.key;
            this.commentCreate.parentKey = params.key;
            this.postItem.ngOnChanges();
            this.comments.ngOnChanges();
        })
    }

}
