import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from "app/people/people.service";

@Component({
	selector: 'app-user-signed-in',
	templateUrl: './user-signed-in.component.html',
	styleUrls: ['./user-signed-in.component.css']
})
export class UserSignedInComponent implements OnInit {

	@Input('uid') uid: string;

	isConnected: any;
	
	constructor(private peopleService: PeopleService) { }

	ngOnInit() {
		this.isConnected = this.peopleService.isUserConnected(this.uid);
	}
}
