import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SitesService } from "app/core/services/sites.service";
import { GroupsService } from "app/groups/groups.service";
import { Observable } from "rxjs";

import { AngularFireAction, DatabaseSnapshot } from '../../../../node_modules/angularfire2/database';
import { NotificationsService } from 'app/notifications/notifications.service';
import { AccountService } from "app/core/services/account.service";
import { AngularFireDatabase } from "angularfire2/database";
import * as XLSX from 'xlsx';

@Component({
	selector: 'app-member-add',
	templateUrl: './member-add.component.html',
	styleUrls: ['./member-add.component.css']
})
export class MemberAddComponent implements OnInit {

	@Output() submitted = new EventEmitter();
	@Output() cancelled = new EventEmitter();

	@Input() isMemberFlag: boolean;  // for adding non-members who get notified of alerts and discussions but aren't part of the group, per se
	
	userForm: FormGroup;
	invitation: Observable<AngularFireAction<DatabaseSnapshot<any>>>;
	personExists = false;
	emailExists = {flag: false, uid: ''};
	emailExistsMember = {flag: false};
	emailExistsNonMember = {flag: false};
	fromName = this.accountService.currentUser.displayName;
	displayName = '';
	uid = '';
	note: string = '';
	firstName = '[First Name]';
	lastName = '';
	key: string = '';
	memberCountObj = {count: 0};
	groupTypeObj = {status: '', name: ''};
	inviteeUID;
	memberKeys = [''];
	text ='Wissle is a private security system that allows me to send alerts to you and others when help is urgently needed. Wissle is the only system that simultaneously delivers alerts via text message, email, and a phone call all at once.  I think you are going to like it. Please accept my INVITE and complete your profile. --  Thank you, ' + this.fromName;

	file: any;
	bulkEmail = [];
	arrEmailExists = [];
	isGroup = false;
	joinObj = {found: '', siteKey: '', code: ''};
	joinCode;
	
	

	constructor(private fb: FormBuilder,
		private sitesService: SitesService,
		public accountService: AccountService,
		private notificationsService: NotificationsService,
		private afDatabase: AngularFireDatabase,
		private groupService: GroupsService) {

		this.userForm = this.fb.group({
			email: ['', Validators.email],
			firstName: [''],
			lastName: [''],
			phoneNumber: [''],			
			note: this.text 
		});
				
		this.userForm.get('email').valueChanges.subscribe(() => {
			if (this.userForm.get('email').valid && this.userForm.get('email').value
				&& this.userForm.get('email').value.length > 0) {
					
				this.sitesService.checkEmailExistence(this.userForm.get('email').value, this.emailExists);
				
				this.invitation = this.sitesService.checkForEmailInvitation(this.userForm.get('email').value).snapshotChanges();
				this.invitation.subscribe(person => {
					if (person.payload.exists() && person.payload.val().isCompleted) {
						this.personExists = true;
						this.displayName = person.payload.val().displayName ? person.payload.val().displayName : person.payload.val().email;
						this.uid = person.payload.val().uid;						
						this.memberKeys[0] = this.uid;
					} else {
						this.personExists = false;
						this.displayName = '';
						this.uid = '';
					}
				});
			}
		});

		// check members of site
		this.userForm.get('email').valueChanges.subscribe(() => {
			if (this.userForm.get('email').valid && this.userForm.get('email').value
				&& this.userForm.get('email').value.length > 0) {
					
				this.sitesService.checkMemberEmailExistence(this.key, this.userForm.get('email').value, this.emailExistsMember);								
			}
		});

				
		// check non-members
		this.userForm.get('email').valueChanges.subscribe(() => {
			if (this.userForm.get('email').valid && this.userForm.get('email').value
				&& this.userForm.get('email').value.length > 0) {
					
				this.sitesService.checkNonMemberEmailExistence(this.key, this.userForm.get('email').value, this.emailExistsNonMember);								
			}
		});
	}

	ngOnInit() {
		this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);})		
	}

	addMember(siteName) {	

		this.notificationsService.create(
			siteName,
			this.key,
			this.accountService.currentUser.photoURL, 
			'/neighborhood/members', 
			this.memberKeys,
			this.displayName
		);		
	}

	addNonMember(siteName) {		
		this.groupService.addNonMember(this.key, 
			this.userForm.get('email').value, 
			this.userForm.get('firstName').value,
			this.userForm.get('lastName').value);
	}
	
	addNonMemberBulk(email, firstName, lastName) {
		this.groupService.addNonMember(this.key, 
			email, 
			firstName,
			lastName);
	}

	removeInvitee(index) {		
		this.bulkEmail.splice(index, 1);
	}
	
	fileChanged(evt: any) {
		/* wire up file reader */
		const target: DataTransfer = <DataTransfer>(evt.target);
		if (target.files.length !== 1) throw new Error('Cannot use multiple files');
		const reader: FileReader = new FileReader();
		reader.onload = (e: any) => {
		  /* read workbook */
		  const bstr: string = e.target.result;
		  const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
	
		  /* grab first sheet */
		  const wsname: string = wb.SheetNames[0];
		  const ws: XLSX.WorkSheet = wb.Sheets[wsname];
	
		  /* save data */
		  this.bulkEmail = (XLSX.utils.sheet_to_json(ws, {header: 1}));	
		  if (!this.validateEmail())
		  {
			this.bulkEmail = [];
			var x = <HTMLInputElement>document.getElementById("fileXLSX");
			x.value = "";
		  }
			
		};
		reader.readAsBinaryString(target.files[0]);		
	}	
	
	/*fileChanged(e) {
		this.file = e.target.files[0];
		let fileReader = new FileReader();
		fileReader.onload = (e) => {
		//console.log(fileReader.result);
		this.bulkEmail = fileReader.result.toString().split("\r\n");	  
		}
		fileReader.readAsText(this.file);
	}*/

	validateEmail() {
		var retVal = true;
		var i = 0;
		for (i = 0; i < this.bulkEmail.length; i++) {


			if (this.bulkEmail[i][0] == undefined || 
				this.bulkEmail[i][1] == undefined ||
				this.bulkEmail[i][2] == undefined) {
				this.bulkEmail.splice(i, 1);
				i--;
				continue;
			}

			if (!this.isEmailValid(this.bulkEmail[i][0].trim()))
			{
				if (i == 0) {
					this.bulkEmail.splice(i, 1);
				} // ignore the first line because it's a header line
				else {
					alert("There is an invalid email address at line " + (i + 1) + ": " + this.bulkEmail[i][0] + ". Fix it and load again.");
					retVal = false;
				}	
				
				//break;
			}	
			if (!this.isNameValid(this.bulkEmail[i][1].trim())) {
				alert("There is an invalid first name at line " + (i + 1) + ": " + this.bulkEmail[i][1] + ". Fix it and load again.");
				retVal = false;
				//break;
			}			
			if (!this.isNameValid(this.bulkEmail[i][2].trim())) {
				alert("There is an invalid last name at line " + (i + 1) + ": " + this.bulkEmail[i][2] + ". Fix it and load again.");
				retVal = false;
				//break;
			}
		}
		return retVal;
	}

	isEmailValid(mail) {
		var mailformat = /\S+@\S+\.\S+/; ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if(mail.match(mailformat))
		{
			return true;
		}
		else
		{			
			return false;
		}
	}

	isNameValid(name) {		
		var nameformat = /^[a-zA-Z]+$/;
		if(name.match(nameformat))
		{
			return true;
		}
		else
		{			
			return false;
		}
	}

	clearInviteList() {
		this.bulkEmail = [];
		var x = <HTMLInputElement>document.getElementById("fileXLSX");
		x.value = "";
	}

	changeListener(files){
		//console.log(files);
		if(files && files.length > 0) {
		   let file : File = files.item(0); 
			 //console.log(file.name);
			 //console.log(file.size);
			 //console.log(file.type);
			 let reader: FileReader = new FileReader();
			 reader.readAsText(file);
			 reader.onload = (e) => {
				let csv: string = reader.result as string;
				//console.log(csv);
			 }
		  }
	  }

	selectNeighborhood(key) {
		if (key != 'default key') {
			this.key = key;		
			let promises = Promise.all(
				[this.sitesService.getCurrentMemberCount(this.key, this.memberCountObj, this.groupTypeObj)]
			);
	
			promises.then((result) => {	
				if (this.groupTypeObj.status == 'small' || 
					this.groupTypeObj.status == 'medium' || 
					this.groupTypeObj.status == 'large')				
					this.isGroup = false;
				else
					this.isGroup = true;
										
				let promises = Promise.all(
					[
						this.accountService.checkJoinCode(this.key, this.joinObj)
					]
				);
		
				promises.then(() => {   
					if (this.joinObj.found == 'true'){
						this.accountService.getSiteJoinCode(this.key, this.joinObj);						
					}
					else {
						// do nothing
					}
				}, error => {					
					alert(error);            
				});	
			});
		}				
	}

	generateJoinCode() {
		this.accountService.addJoinCode(this.key);

		let promises = Promise.all(
			[
				this.accountService.checkJoinCode(this.key, this.joinObj)
			]
		);

		promises.then(() => {   
			debugger;	                                                 
			if (this.joinObj.found == 'true'){
				this.accountService.getSiteJoinCode(this.key, this.joinObj);						
			}			
		}, error => {					
			alert(error);            
		});	
	}

	submit() {	
										
		var bCanAdd = false;
		var emailExists = {flag: false, uid: ''};		
		var siteName;
		var groupTypeObj = {status: '', name: ''};
		var defaultText = this.text;
		
		for (var i = 0; i < this.bulkEmail.length; i++)
			this.arrEmailExists.push(this.emailExists);

		let promises = Promise.all(
			[this.sitesService.getCurrentMemberCount(this.key, this.memberCountObj, groupTypeObj)]
		);

		promises.then((result) => {	
					
			siteName = groupTypeObj.name;		
			if (groupTypeObj.status == 'small')
			{
				if (this.bulkEmail.length > 0)
				{
					if (this.bulkEmail.length + this.memberCountObj.count < 100)
						bCanAdd = true;
					else
						alert('There is not enough room left in your community.');
				}
				else
				{
					if (this.memberCountObj.count < 100)
						bCanAdd = true;
					else
						alert('There are already 100 members in your community.');
				}
				
			}
			else if (groupTypeObj.status == 'medium')
			{
				if (this.bulkEmail.length > 0)
				{
					if (this.bulkEmail.length + this.memberCountObj.count < 200)
						bCanAdd = true;
					else
						alert('There is not enough room left in your community.');
				}
				else
				{
					if (this.bulkEmail.length + this.memberCountObj.count < 200)
						bCanAdd = true;
					else
						alert('There are already 200 members in your community.');
				}
				
			}
			else if (this.bulkEmail.length + groupTypeObj.status == 'large')
			{
				if (this.bulkEmail.length > 0)
				{
					if (this.bulkEmail.length + this.memberCountObj.count < 500)
						bCanAdd = true;
					else
						alert('There is not enough room left in your community.');
				}
				else
				{
					if (this.memberCountObj.count < 500)
						bCanAdd = true;
					else
						alert('There are already 500 members in your community.');
				}
				
			}
			else if (groupTypeObj.status == 'essential' ||
					groupTypeObj.status == 'plus' ||
					groupTypeObj.status == 'premium')
			{
				if (this.bulkEmail.length > 0)
				{
					if (this.bulkEmail.length + this.memberCountObj.count < 25)
						bCanAdd = true;
					else
						alert('There is not enough room left in your group.');
				}
				else
				{
					if (this.memberCountObj.count < 25)
						bCanAdd = true;
					else
						alert('There are already 25 members in your group.');
				}
				
			}
			else {
				alert("Your group subscription is '" + groupTypeObj.status + "' which is not one of the possibilities: small, medium, large, essential, plus, and premium.");
			}

			if (bCanAdd)
			{
				if (this.bulkEmail.length > 0)
				{					
					var promises = [];					
					for(var j=0;j<this.bulkEmail.length;j++) {
						promises.push(this.sitesService.checkEmailExistence2(this.bulkEmail[j][0], this.arrEmailExists[j]));
					}

					Promise.all(promises)
					.then((results) => {
						
						for (var k = 0; k < results.length; k++) {
							if (results[k].node_.children_.root_.key != undefined){

								if (!this.isMemberFlag)
								{
									if (this.emailExistsNonMember.flag)
									{								
										alert("This Email already belongs to one of your non-members!");
										continue;
									}
									else if (this.emailExistsMember.flag)
									{
										alert("This Email already belongs to one of your members!");
										continue;
									}															
									else
										this.addNonMemberBulk(this.bulkEmail[k][0].trim(), 
															this.bulkEmail[k][1].trim(),
															this.bulkEmail[k][2].trim());
								}
								else
								{
									this.memberKeys[0] = results[k].node_.children_.root_.key;
									emailExists.flag = true;																		
									this.addMember(siteName);
								}
								
							}
							else {
								emailExists.flag = false;
							}

							this.sitesService.sendEmailInvite(
								!emailExists.flag,							
								this.bulkEmail[k][0].trim(),
								this.bulkEmail[k][1].trim(),
								this.bulkEmail[k][2].trim(),
								this.userForm.get('phoneNumber').value,				
								this.userForm.get('note').value);
									
							this.sitesService.sendAlertInvite(
								this.key,
								this.bulkEmail[k][0].trim(),
								this.bulkEmail[k][1].trim(),
								this.bulkEmail[k][2].trim(),
								this.userForm.get('phoneNumber').value,				
								this.userForm.get('note').value);							
						}

						this.submitted.next();
						this.userForm.reset();
						
						this.userForm.patchValue({note: defaultText}); 
					})
					.catch((e) => {
						// Handle errors here
						alert("Error sending bulk mail invites!");
					});	
										
				}
				else {
					
					if (this.userForm.valid) {
						if (!this.isMemberFlag)
						{
							
							if (this.emailExistsNonMember.flag)
							{								
								alert("This Email already belongs to one of your non-members!");
								return;
							}
							else if (this.emailExistsMember.flag)
							{
								alert("This Email already belongs to one of your members!");
								return;
							}															
							else
								this.addNonMember(siteName);
								
						}							
						else if (this.personExists)	
							this.addMember(siteName);
						else if (this.emailExists.flag)
						{
							this.memberKeys[0] = this.emailExists.uid;
							this.addMember(siteName);
						}
						
						// send email invite
						this.sitesService.sendEmailInvite(
							!this.emailExists.flag,							
							this.userForm.get('email').value,
							this.userForm.get('firstName').value,
							this.userForm.get('lastName').value,
							this.userForm.get('phoneNumber').value,				
							this.userForm.get('note').value);
							
						this.sitesService.sendAlertInvite(
							this.key,
							this.userForm.get('email').value,
							this.userForm.get('firstName').value,
							this.userForm.get('lastName').value,
							this.userForm.get('phoneNumber').value,				
							this.userForm.get('note').value);
																													
						this.submitted.next();
						this.userForm.reset();
						
						this.userForm.patchValue({note: defaultText}); 
					}
				}
				
				
			}				
			
		}, error => {					
			alert(error)
		});	

	}

	cancel() {
		this.cancelled.next();
	}

}
