import { AccountModule } from './../account/account.module';
import { AgmCoreModule } from '@agm/core';
import { PostsRoutingModule } from './posts-routing.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap';
import { PostItemComponent } from './post-item/post-item.component';
import { PostItemListComponent } from './post-item-list/post-item-list.component';
import { SharedModule } from "app/shared/shared.module";
import { CommentsModule } from "app/comments/comments.module";
import { PostTypesModule } from "app/posts/post-types/post-types.module";
import { PostItemCreateComponent } from './post-item-create/post-item-create.component';
import { FormsModule } from "@angular/forms";
//import { ImagesModule } from "app/images/images.module";
import { ImageCropperModule } from 'ngx-image-cropper';
import { PostsService } from "app/posts/posts.service";
import { PeopleModule } from "app/people/people.module";
import { ReactionsModule } from "app/reactions/reactions.module";
import { GroupsModule } from "app/groups/groups.module";
import { FilesModule } from "app/files/files.module";
import { PostsComponent } from './posts/posts.component';
import { PostItemModalComponent } from './post-item-modal/post-item-modal.component';
import { NeighborhoodModule } from "app/neighborhood/neighborhood.module";
import { SlidesModule } from 'app/slides/slides.module';

@NgModule({ 
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		BsDropdownModule.forRoot(),
		CommentsModule,
		PostTypesModule,
		ImageCropperModule,
		PeopleModule,
        ReactionsModule,
        GroupsModule,
        FilesModule,
        AgmCoreModule.forRoot(),
		PostsRoutingModule,
        NeighborhoodModule,
        AccountModule,
		SlidesModule,
	],
    exports: [
		PostItemComponent,
		PostItemListComponent,
		PostItemCreateComponent
	],
	declarations: [PostItemComponent, PostItemListComponent, PostItemCreateComponent, PostsComponent, PostItemModalComponent],
	providers: [PostsService]
})
export class PostsModule { }
