import { ContactHelpComponent } from './contact-help/contact-help.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from "app/marketing/contact/contact.component";
import { ContactJoinComponent } from "app/marketing/contact/contact-join/contact-join.component";
import { ContactQuestionsComponent } from "app/marketing/contact/contact-questions/contact-questions.component";
import { ThankYouComponent } from "app/marketing/contact/thank-you/thank-you.component";

const routes: Routes = [
    {
        path: 'marketing/contact', component: ContactComponent,
        children: [
            { path: 'help', component: ContactHelpComponent },
            { path: 'join', component: ContactJoinComponent },
        { path: 'questions', component: ContactQuestionsComponent },
        { path: 'thank-you', component: ThankYouComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ContactRoutingModule { }
