import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AccountService } from "app/core/services/account.service";
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database';

declare var $ :any;


@Component({
	selector: 'app-update-profile-image',
	templateUrl: './update-profile-image.component.html',
	styleUrls: ['./update-profile-image.component.css']
})
export class UpdateProfileImageComponent implements OnInit {

@Output() updated = new EventEmitter<boolean>();


	private _peopleRef = this.db.database.ref('people');

	data: any;
	file: any;
    imageChangedEvent: any = '';
	croppedImage: any = '';
	imageFileName: any = '';
	uploadProgress;	
	fileUploadTask = null;	
	memberKey: string;

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;	
		var image:any = new Image();					
		this.file = event.target.files[0];
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;								
			that.data.image = image.src;						
		};
		myReader.readAsDataURL(this.file);
	}
	
    imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}
	
    imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.data.image = image;	
	}
	
    cropperReady() {
		// cropper ready
	}
	
    loadImageFailed() {
		// show message
	}
	
	constructor(private accountService: AccountService,
		private db: AngularFireDatabase,
		) {        
        this.data = {};
    }

	ngOnInit() {
		//this.croppedImage = this.data.image = this.backgroundImage;
	}

	// updateProgressBar(value) {
	// 	this.uploadProgress = value;
	// }

	updateProfileImage3(file: File, data: string, userKey: string) {
		data = data.replace(`data:image/png;base64,`, '');
		let storage = firebase.storage();
		let storageRef = storage.ref();
		let fileStorageRef = storageRef.child('profile-images').child(userKey);
		this.fileUploadTask = fileStorageRef.putString(
			data,
			'base64',
			{ customMetadata: { updatePath: `people/${userKey}/profileImage` }, cacheControl: 'max-age=31536000', contentType: file.type });

		console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);

		this.fileUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
		function(snapshot) {
			//debugger;
		  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
		  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		  console.log('Upload is ' + progress + '% done');
		//   this.zone.run(() => {
		// 	this.uploadProgress = progress;
		//   });
		  
		  switch (snapshot.state) {
			case firebase.storage.TaskState.PAUSED: // or 'paused'
			  console.log('Upload is paused');
			  break;
			case firebase.storage.TaskState.RUNNING: // or 'running'
			  console.log('Upload is running');
			  break;
		  }
		}, function(error) {
	  
		// A full list of error codes is available at
		// https://firebase.google.com/docs/storage/web/handle-errors
		switch (error.message) {
		  case 'storage/unauthorized':
			// User doesn't have permission to access the object
			break;
	  
		  case 'storage/canceled':
			// User canceled the upload
			break;
	  
		  case 'storage/unknown':
			// Unknown error occurred, inspect error.serverResponse
			break;
		}
	   
	  });

		return this.fileUploadTask
			.then(snapshot =>   {				
				return snapshot.task.then(uploadTask => {
					console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);
					return uploadTask.ref.getDownloadURL().then(downloadURL => {
						return this.db.object(this._peopleRef.child(userKey).child('profileImage'))
							.update({
								downloadURL: downloadURL,
								downloadURLOriginal: downloadURL
							});
					});
				});
			});
	}

	update(key) {
		//console.log(this.data.image)
			
		if (key != null)
			this.memberKey = key;		
		else
			this.memberKey = this.accountService.currentUser.uid;

		try {
			//this.accountService.updateProfileImage2(this.file, this.data.image, this.updated, this.obj);
			this.updateProfileImage3(this.file, this.data.image, this.memberKey)			
			 .then(() => {
			 	this.updated.emit();

			 	// this.imageChangedEvent = null;				
			 	this.croppedImage = this.data.image;
			 }, error => {

			 });
		}catch(e) {
			console.log(e)
		}
	}
}
