
import {mergeMap, take} from 'rxjs/operators';
import { SitesService } from 'app/core/services/sites.service';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { NotificationsService } from "app/notifications/notifications.service";
import * as firebase from 'firebase';
import { AccountService } from "app/core/services/account.service";
import { GroupsService } from "app/groups/groups.service";
import { BaseGroupService } from "app/core/services/base-group.service";
import { Observable } from "rxjs";
import { element } from 'protractor';
import { map } from 'rxjs/operators';

export class BaseDiscussionService {

	private _siteKey = null;
	private _pageSize = 10;
	private _feedKeys = [];	

	private _discussionRef: firebase.database.Reference;
	private _tagsByDiscussionRef: firebase.database.Reference;
	private _userDiscussionsRef: firebase.database.Reference;
	private _siteDiscussionsRef: firebase.database.Reference;
	private _userFeed: firebase.database.Reference;
	private _spam: firebase.database.Reference;

	public hasMore = new EventEmitter(false);
	

	constructor(
		private type: string,
		private db: AngularFireDatabase,
		private afAuth: AngularFireAuth,
		private accountSerivce: AccountService,
		private notificationsService: NotificationsService,
		private memberGroupsService: GroupsService,
		private sitesSerivce: SitesService) {

		this._discussionRef = this.db.database.ref(`${type}s`);
		this._tagsByDiscussionRef = this.db.database.ref(`${type}-by-tag`);
		this._userDiscussionsRef = this.db.database.ref(`user-${type}s`);
		this._siteDiscussionsRef = this.db.database.ref(`site-${type}s`);
		this._userFeed = this.db.database.ref(`user-${type}s-feed`);
		this._spam = this.db.database.ref(`spam-${type}s`);

		if (type == 'discussion-item')
			this._pageSize = 10000000;  // 10 million

		this.accountSerivce.currentSiteKey.subscribe(key => {
			this._feedKeys = [];			

			this._siteKey = key;
			this.subscribeToFeed();			
		});
	}

	getItem(key: string) {
		return this.db.object(this._discussionRef.child(key));
	}

	
	getItem2(key: string, obj_) {				
		return this._discussionRef.child(key).once("value")
		  .then(function(snapshot) {
			obj_.itm = snapshot.val();
		  });			
	}

	//getFeed() {	
	
	//		return this._feedKeys;
	//}

	getFeed() {			
		return this._feedKeys;		
	}

	markAsSpam(key: string, comment: string) {
		this.db.object(this._spam.child(key).child(this.afAuth.auth.currentUser.uid)).update({
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			comment: comment
		});
	}

	delete(key: string) {
		this._feedKeys.splice(this._feedKeys.indexOf(key), 1);
		return this.accountSerivce.currentSiteKey.pipe(
			take(1))
			.subscribe(siteKey => {
				this.db.list(`${this.type}-recipients/${key}/`)
					.snapshotChanges().pipe(
					take(1))
					.subscribe(recipients => {
						recipients.forEach(ref => {
							this.db.object(this._userFeed.child(siteKey).child(ref.payload.key).child(key)).remove();
						});

					
						this._discussionRef.child(key).remove();
						this.db.object(`site-${this.type}s/${siteKey}/${key}`).remove();
						this.db.object(`user-${this.type}s/${this.accountSerivce.currentUser.uid}/${key}`).remove();
						this.db.object(`${this.type}-recipients/${key}/`).remove();
					});
			});
	}

	removeFromFeed(key: string) {

	}

	loadMore() {
		let firstKey = this._feedKeys ? this._feedKeys[this._feedKeys.length - 1] : '';
		this.db.list(this._userFeed.child(this._siteKey).child(this.afAuth.auth.currentUser.uid),
			queryFN => queryFN.orderByKey().endAt(firstKey).limitToLast(this._pageSize))
			.snapshotChanges()
			.pipe(map(refs => refs.map(ref => ref.key)))
			.subscribe(keys => {
				keys.reverse().forEach(key => {
					if (this._feedKeys.indexOf(key) === -1)											
						this._feedKeys.push(key);																	
				});

				this.hasMore.next(keys.length >= this._pageSize)
			});
	}

	getTags() {
		return this.accountSerivce.currentSiteKey
			.pipe(mergeMap(siteKey=> {
				return this.db.list(this._tagsByDiscussionRef.child(siteKey))
					.snapshotChanges()					
			}));

		/*return Observable.create(observer => {
			this.accountSerivce.currentSiteKey
				.subscribe(siteKey => {
					this.db.list(this._tagsByDiscussionRef.child(siteKey))
						.snapshotChanges()
						.subscribe(tags => observer.next(tags));
				});
		});*/
	}	

	getTopicCount(siteKey: string) {		
		return this.db.list(this._userFeed.child(siteKey).child(this.afAuth.auth.currentUser.uid));			
	}
	
	protected subscribeToFeed() {
		
		this.db.list(this._userFeed.child(this._siteKey).child(this.afAuth.auth.currentUser.uid),
			queryFN => queryFN.orderByKey().limitToLast(this._pageSize))
			.snapshotChanges()
			.pipe(map(refs => refs.map(ref => ref.key)))
			.subscribe((keys: string[]) => {
				keys
					.forEach(key => {						
						if (this._feedKeys.indexOf(key) === -1)
							this._feedKeys.splice(0, 0, key)
					});
				this.hasMore.next(keys.length >= this._pageSize)
				if (this._feedKeys.length == 0)
				{					
					if (this.type != 'discussion-item')
						this.getPastAlerts();
					else 
						this.getPastDiscussions();
				}
					
			});
	}

	getPastDiscussions() {						
		var that = this;
		var firstKey = '';
		
		if (this._siteKey == null)
			return;

		this._userFeed.child(this._siteKey).once("value")
		.then(function(snapshot) {
			snapshot.forEach(function(childSnapshot) {																			
				if (firstKey == '') {  // only create discussion items from one other user
					firstKey = childSnapshot.key;
					that.getDiscussionUsers(childSnapshot.key);
				}																									
			});
		});
	}

	getDiscussionUsers(key) {
		var event;
		var eventParsed;		
		this.db.list(this._userFeed.child(this._siteKey + '/' + key))		
		.snapshotChanges()						
		.subscribe(snapshot => {
			snapshot
			.forEach(rec => {
				//console.log('KEY:', key);					
				event = JSON.stringify(rec);
				eventParsed = JSON.parse(event);
				//console.log('RECORD', event);
				var record = rec;
				var ref = this._userFeed.child(this._siteKey).child(this.afAuth.auth.currentUser.uid).child(record.key);
				ref.set(
					{category: eventParsed.payload.category,
					 createdAt: eventParsed.payload.createdAt,
					 createdBy: eventParsed.payload.createdBy,
					 createdByName: eventParsed.payload.createdByName,
					 groupKey: eventParsed.payload.groupKey,
					 isPrivate: eventParsed.payload.isPrivate,
					 isPublic: eventParsed.payload.isPublic,
					 order: eventParsed.payload.order,
					 shortMessage: eventParsed.payload.shortMessage,
					 siteKey: eventParsed.payload.siteKey,
					 tags: eventParsed.payload.tags,
					 text: eventParsed.payload.text,
					 title: eventParsed.payload.title,
					 price: eventParsed.payload.price == undefined ? null: eventParsed.payload.price,
					 startDate: eventParsed.payload.startDate == undefined ? null: eventParsed.payload.startDate,
					 startTime: eventParsed.payload.startTime == undefined ? null: eventParsed.payload.startTime,
					 endDate: eventParsed.payload.endDate == undefined ? null: eventParsed.payload.endDate,
					 endTime: eventParsed.payload.endTime == undefined ? null: eventParsed.payload.endTime});
			});						
		});
	}	

	getPastAlerts() {						
		var that = this;
		var firstKey = '';
		if (this._siteKey == null)
			return;
			
		this._userFeed.child(this._siteKey).once("value")
		.then(function(snapshot) {
			snapshot.forEach(function(childSnapshot) {																			
				if (firstKey == '') {  // only create alert items from one other user
					firstKey = childSnapshot.key;
					debugger;
					that.getAlertUsers(childSnapshot.key);
				}																									
			});
		});
	}
	
	getAlertUsers(key) {
		var event;
		var eventParsed;
		this.db.list(this._userFeed.child(this._siteKey + '/' + key))		
		.snapshotChanges()						
		.subscribe(snapshot => {
			snapshot
			.forEach(rec => {
				//console.log('KEY:', key);					
				event = JSON.stringify(rec);
				eventParsed = JSON.parse(event);
				
debugger;
				var record = rec;
				var ref = this._userFeed.child(this._siteKey).child(this.afAuth.auth.currentUser.uid).child(record.key);
				if (!eventParsed.payload.isPrivate) {
					ref.set(
						{address: eventParsed.payload.address == undefined ? null: eventParsed.payload.address,
						createdAt: eventParsed.payload.createdAt,
						createdBy: eventParsed.payload.createdBy,
						createdByName: eventParsed.payload.createdByName,
						groupKey: eventParsed.payload.groupKey,
						isPrivate: eventParsed.payload.isPrivate,
						isPublic: eventParsed.payload.isPublic,
						isUrgent: eventParsed.payload.isUrgent,
						latitude: eventParsed.payload.latitude,
						longitude: eventParsed.payload.longitude,					 
						order: eventParsed.payload.order,
						postType: eventParsed.payload.postType,
						shortMessage: eventParsed.payload.shortMessage,
						siteKey: eventParsed.payload.siteKey,
						text: eventParsed.payload.text
					});
				}
			});	
									
		});
	}

	protected edit(text: string, key: string) {
		return this.db.object(this._discussionRef.child(key).child('text')).set(text);
	}

	protected create(text: string, shortMessage: string, groupKey: string = null, metadata: any = null, tags: string[], albumKey: string = null, titleColor: string = null, sendNotificationEmail: boolean ) {
		
		this.accountSerivce.getUserDetails()
			.valueChanges().pipe(
			take(1))
			.subscribe((currentUser: any) => {
				
				let service: BaseGroupService = (groupKey == 'private' || groupKey == 'site') ? this.sitesSerivce : this.memberGroupsService;
				let isPrivate = groupKey == 'private';
				let isPublic = groupKey == 'site';

				groupKey = (groupKey == 'private' || groupKey == 'site') ? this._siteKey : groupKey;
				tags = tags.map(tag => tag.toLowerCase());

				return service.getMemberKeys(groupKey).pipe(
					take(1))
					.subscribe(memberKeys => {
						
						let feed = {};
						let postRecipients = {};
						let post = Object.assign({
							text: text,
							shortMessage: shortMessage,
							tags: tags,
							siteKey: this._siteKey,
							groupKey: groupKey,
							albumKey: albumKey,
							titleColor: titleColor,
							isPublic: isPublic,
							isPrivate: isPrivate,
							createdAt: firebase.database.ServerValue.TIMESTAMP,
							createdBy: this.afAuth.auth.currentUser.uid,
							createdByName: currentUser.displayName,
							sendNotificationEmail: sendNotificationEmail
						}, metadata);

						let ref = this.db.list(this._discussionRef).push(post);

						tags.forEach(tag => {
							this.db.object(this._tagsByDiscussionRef.child(this._siteKey).child(btoa(tag)).child(ref.key)).set(true);
						});
						
						if (isPrivate) {

							feed[`${this.afAuth.auth.currentUser.uid}/${ref.key}`] = Object.assign({
								order: -(new Date).getTime()
							}, post);

							//this.db.object(`${this.type}-recipients/${ref.key}/${this.afAuth.auth.currentUser.uid}`).set(true);
							postRecipients[`${this.type}-recipients/${ref.key}/${this.afAuth.auth.currentUser.uid}`] = true;
							
						} else {
							
							memberKeys.forEach(key => {
								feed[`${key}/${ref.key}`] = Object.assign({
									order: -(new Date).getTime()
								}, post);

								//this.db.object(`${this.type}-recipients/${ref.key}/${key}`).set(true);
								postRecipients[`${this.type}-recipients/${ref.key}/${key}`] = true;
								
							});

							this.notificationsService.create(
								shortMessage,
								ref.key, 
								currentUser.profileImage.downloadURL,
								this.type == 'post' ?
									['/neighborhood/alerts', { outlets: { modal: ['view', ref.key] } }] :
									['neighborhood', 'discussion', ref.key],
								memberKeys,
								currentUser.displayName
							);
						}

						this.db.object(this._userFeed.child(this._siteKey)).update(feed);
						this.db.object(this.db.database.ref()).update(postRecipients);
						this.db.object(this._userDiscussionsRef.child(this.afAuth.auth.currentUser.uid).child(ref.key)).set(true);

						if (isPublic)
							this.db.object(this._siteDiscussionsRef.child(this._siteKey).child(ref.key)).set(true);

						return ref;
					});
			});
	}
}
