import { Directive, OnChanges, Input, ElementRef } from '@angular/core';
import { AlbumsService } from "app/core/services/albums.service";

@Directive({
	selector: '[albumCoverImageSrc]'
})
export class AlbumCoverImageSrcDirective implements OnChanges {

	@Input('albumCoverImageSrc') key: string;

	constructor(private albumsService: AlbumsService, private elementRef: ElementRef) { }

	ngOnChanges() {
		this.albumsService.getAlbum(this.key)
			.valueChanges()
			.subscribe((album: any) => {
				let keys = Object.keys(album.images);

				if (keys.length) {
					this.elementRef.nativeElement.src = album.images[keys[0]].downloadURL;
				}
			});
	}
}
