import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-cameras-item-edit',
    templateUrl: './cameras-item-edit.component.html',
    styleUrls: ['./cameras-item-edit.component.css']
})
export class CamerasItemEditComponent implements OnInit {

    key: string;
    cameraName: string = '';
    cameraLocation: string = '';
    cameraSocation: string = '';

    constructor(private activatedRoute: ActivatedRoute, private titleService: Title) {
        titleService.setTitle('Wissle Cameras Modal');
     }

    ngOnInit() {
        this.activatedRoute.params
			.subscribe(params => {
				this.key = params.key;
			});
    }

    cancel() {
        // do something
    }

}
