import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class ContactService {

    contactHelpRef = this.db.database.ref('contact/help');
    questionpRef = this.db.database.ref('contact/question');
    joinRef = this.db.database.ref('contact/join');
    remindRef = this.db.database.ref('contact/remind');

    constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {
    }

    private varifyAuthentication() {
        return new Promise((resolve, reject) => {
            if (this.afAuth.auth.currentUser == null) {
                this.afAuth.auth.signInAnonymously()
                    .then(() => resolve());
            } else {
                resolve();
            }
        });
    }

    help(email: string, name: string, phoneNumber: string, description: string) {
        return this.varifyAuthentication()
            .then(() => this.db.list(this.contactHelpRef)
                .push({
                    email: email,
                    name: name,
                    phoneNumber: phoneNumber,
                    description: description,
                    createdOn: firebase.database.ServerValue.TIMESTAMP
                })
            );
    }

    join(email: string, name: string, phoneNumber: string, description: string, hoaName: string, memberCount: string, address: string) {
        return this.varifyAuthentication()
            .then(() => this.db.list(this.joinRef)
                .push({
                    email: email,
                    name: name,
                    phoneNumber: phoneNumber,
                    description: description,
                    hoaName: hoaName,
                    memberCount: memberCount,
                    createdOn: firebase.database.ServerValue.TIMESTAMP
                })
            );
    }

    question(email: string, name: string, phoneNumber: string, description: string, subject: string) {
        return this.varifyAuthentication()
            .then(() => this.db.list(this.questionpRef)
                .push({
                    email: email,
                    name: name,
                    phoneNumber: phoneNumber,
                    description: description,
                    subject: subject,
                    createdOn: firebase.database.ServerValue.TIMESTAMP
                })
            );
    }
    remind(email: string, category: string) {
        return this.varifyAuthentication()
            .then(() => this.db.list(this.questionpRef)
                .push({
                    email: email,
                    category: category,
                    createdOn: firebase.database.ServerValue.TIMESTAMP
                })
            );
    }
}
