import { DiscussionService } from 'app/discussion/discussion.service';
import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ModalComponent } from "app/shared/modal/modal.component";
import { DiscussionItemComponent } from './../discussion-item/discussion-item.component';
import { SearchDiscussion } from '../../shared/pipes/search-discussion.pipe';

@Component({
    selector: 'app-discussion-list',
    templateUrl: './discussion-list.component.html',
    styleUrls: ['./discussion-list.component.css']
})
export class DiscussionListComponent implements OnInit, OnChanges {


    @ViewChild(ModalComponent) postModal: ModalComponent;
    @ViewChild('discussionItem') discussionItem: DiscussionItemComponent;

    discussion: any;
    category: string = 'discussion board';
    count: number = 0;
    query: string = '';
    queryDate: string = '';
    queryDateFormatted: string = '';
    current: boolean = false;

    constructor(public discussionService: DiscussionService) { }
    
    ngOnInit() {        
        this.discussionService.currentMessage.subscribe(message => {this.changeCategory(message);});   
        this.discussionService.currentMsgCount.subscribe(count => {this.changeCount(count);});
    }

    ngOnChanges() {        
    }

    getFeed() {
        this.discussionService.getFeed();
    }

	loadMore() {
        this.discussionService.loadMore();        
    }

    trackByFn(i) {
        if (i > 0 && i % 3 == 0 || this.discussionService.hasMore)
            this.loadMore();
    }

    hasMore() {        
        return this.discussionService.hasMore;        
    }
    
    changeCategory(message) {   
        this.category = message;
    }

    changeCount (count) {
        this.count = count;
    }
     
    onDateValueChange(event){
        if (event != null)
        {
            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };                
            //this.discussionService.createPost(this.title, this.text, this.price, this.bsValueStartDate.toLocaleDateString("en-US", options)
            debugger;
            this.queryDateFormatted = event.toLocaleDateString("en-US", options);
        }
        else{
            this.queryDateFormatted = '';
        }

    }
}
