import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, ElementRef, AfterViewInit, HostListener  } from '@angular/core';
import { ModalComponent } from "app/shared/modal/modal.component";
import { PostsService } from "app/posts/posts.service";
import { AccountService } from "app/core/services/account.service"; 
import { CommentsService } from "app/comments/comments.service";
import { CommentItemListComponent } from './../../comments/comment-item-list/comment-item-list.component';
import { ActivatedRoute } from '@angular/router';

declare var google: any;

@Component({
	selector: 'app-post-item',
	templateUrl: './post-item.component.html',
	styleUrls: ['./post-item.component.css']
})
export class PostItemComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() key: string;
	@Input() showMap = false;
	@Input() childNumber: number;
	@Input() indexOfelement: number;

	@Output() commentsSelected = new EventEmitter<string>();
	@Output() messageEventVisible = new EventEmitter<any>();  // sent to post-item-list to indicate card is visible
	@Output() messageEventHover = new EventEmitter<any>();  // sent to post-item-list to indicate card is visible
	@Output() addressSelected = new EventEmitter<string>();

	@ViewChild('deleteModal') deleteModal: ModalComponent;
	@ViewChild('comments') comments: CommentItemListComponent;
	@ViewChild('postItem') postItem: PostItemComponent;
	
	canDelete = false;
	editing = false;

	spamComment = '';

	post: any;
	post2: any;

	loc = {lat: 0.0, lng: 0.0, itemHeight: 0.0, createdAt: 0, mapIconPath: '', $key: ''};
	locSent = false;
	//element: HTMLElement;
	objCount = {count: 0};
	prevObjCount = {count: 0};
	hovering;
	source = 'Alert';  // helps load the comments from latest to first
	firstLoad = true;
		  
	sendMessageVisible(loc: any) {	
		
	  	this.messageEventVisible.emit(loc);
	}

	sendMessageHover(loc) {
		
		this.messageEventVisible.emit(loc);
	}

	IsHovering(val) {
								
		this.loc.lat = val.latitude;
		this.loc.lng = val.longitude;	
		this.loc.createdAt = val.createdAt;
		this.loc.mapIconPath = val.postType.mapIconPath;
		
		this.loc.$key = this.key;
		this.canDelete = (val.createdBy == this.accountService.currentUser.uid);
		if (!this.locSent)
		{
			this.locSent = true;
			//this.sendMessageVisible(this.loc);			
			this.sendMessageHover(this.loc);	
		}
		else	 			
			this.sendMessageHover(this.loc);					
	}



	constructor(public postsService: PostsService,
		        private accountService: AccountService,
				private commentsService: CommentsService,
				private activatedRoute: ActivatedRoute) { }

	ngOnInit() {	
		this.commentsService.getCommentCount(this.key, this.objCount, this.prevObjCount);

		this.post = this.postsService.getItem(this.key).valueChanges();	

		// get the item to put its location/pin on the map
		let post2 = {itm: Object};
		let promises = Promise.all(
			[
				this.postsService.getItem2(this.key, post2)
			]
		);
		 
		promises.then(() => {	
			if (this.firstLoad)
			{
				this.firstLoad = false;
				if (this.indexOfelement == 0) {
					this.IsHovering(post2.itm);
				}
			}
			
		}, error => {					
			alert(error)
		});	
		
			
		this.accountService.isNeighborhoodAdministrator
				.subscribe(isAdmin => {				
					this.canDelete = isAdmin;
				});

		this.activatedRoute.params.subscribe(params => {
			if (params.key != undefined && this.postItem != undefined) {
				this.postItem.key = params.key;
				if (this.comments != undefined) {
					this.comments.parentKey = params.key;
					this.comments.ngOnChanges();
				}					
			}						
		});		
	}	

	ngAfterViewInit() {							
    }

	ngOnChanges() {					
	}

	markAsSpam() {
		this.postsService.markAsSpam(this.key, this.spamComment);
		this.spamComment = '';
	}

	tryMarkAsSpam(post) {
		let message = prompt("(Optional) Let us know what you see wrong with this alert");
		if (message != null) {
			this.postsService.markAsSpam(this.key, message);
		}
	}

	tryDelete(post) {
		if (confirm("Click 'ok' to delete this message")) {
			this.postsService.delete(this.key);
		}
	}

	edit(val){
		this.editing = val;
	}
	cancel(){
		this.edit(false);
	}
	update(post) {
		this.postsService.editPost(post.text, this.key);
		this.edit(false);
	}
}
