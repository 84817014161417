import { PostsService } from './../../posts.service';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-post-types-list',
	templateUrl: './post-types-list.component.html',
	styleUrls: ['./post-types-list.component.css']
})
export class PostTypesListComponent implements OnInit {

	postTypes: any;

	constructor(private postsService: PostsService) { }

	ngOnInit() {
		//this.postTypes = this.postsService.getPostTypes().valueChanges();
		this.postTypes = this.postsService.getPostTypes()
			.snapshotChanges()
			.pipe(map(types => {
				return types.map(postType => {
					return Object.assign({ $key: postType.key }, postType.payload.val());
				});
			}));
	}

}
