import { NotificationsService } from './../../notifications/notifications.service';
import { AppEngineService } from 'app/app-engine.service';
import { Component, OnInit } from '@angular/core';
import { AccountService } from "app/core/services/account.service";
import { Router } from "@angular/router";
import { SitesService } from "app/core/services/sites.service";
import { AngularFireAuth } from "angularfire2/auth";
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

	isAuthenticated = false;
	hasNewNotifications: any;
	unreadNotificationCount: any;
	notifications: any;

	groupObj = {count:0};
	subscriptionObj = {status:''};

	communityBoughtObj = {count: 0};
	communityObj = {status:''};
	communityGroupObj = {count: 0};
	nameObj = {displayName: ''};

	constructor(private notificationsService: NotificationsService,
		private router: Router,
		private accountService: AccountService,
		private sitesService: SitesService, 
		private afAuth: AngularFireAuth,
		private appEngine: AppEngineService) { }

	ngOnInit() {

		this.accountService.isAuthenticated.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
			
			if (isAuthenticated) {
				this.hasNewNotifications = this.notificationsService.hasNewNotifications().valueChanges();
				this.notifications = this.notificationsService.getNotifications()
					.snapshotChanges()
					.pipe(map(snapshots => snapshots.map(snapshot => Object.assign({ key: snapshot.key }, snapshot.payload.val()))))

				this.notificationsService.getNotifications()
					.valueChanges()
					.subscribe((notifications: any) => {
						this.unreadNotificationCount = notifications.map(notification => notification.isRead)
							.filter(isRead => !isRead).length;
					});
			}
		});

		this.updateNeighborhood('default key');
		
	  	this.sitesService.currentMessageKey.subscribe(message => {this.updateNeighborhood(message);})
	}

	updateNeighborhood(key) {
		
			this.appEngine.auth.onAuthStateChanged((user) => {
				if (user) {       
					this.sitesService.getUserStatus(user.uid, this.subscriptionObj, this.groupObj);
					this.sitesService.getUserCommunityInfo(user.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj);  				 
				} else {        
					// do nothing
				}
		});		
	}

	setNotificationAsRead(key: string) {
		this.notificationsService.setNotificationAsRead(key);
	}

	clearNotifications() {
		this.notificationsService.clearNewNotifications();
	}

	signout() {
		this.accountService.signout();
		this.router.navigate(['/login']);
	}

	truncate(input) {
		if (input.length > 40) {
		   return input.substring(0, 40) + '...';
		}
		return input;
	 };

}
