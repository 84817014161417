import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    email = '';

    constructor(private afAuth: AngularFireAuth,
        private router: Router,
        private titleService: Title) {
            titleService.setTitle('Wissle Reset Password'); }

    ngOnInit() {
    }

    submit() {
        this.afAuth.auth.sendPasswordResetEmail(this.email)
            .then(() => this.router.navigate(['/reset-password', this.email]));

    }
}
