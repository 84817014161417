
import {take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import { AccountService } from "app/core/services/account.service";
import * as firebase from 'firebase';
import { NotificationsService } from "app/notifications/notifications.service";
import { Observable} from "rxjs";
import { map } from 'rxjs/operators';

@Injectable()
export class CommentsService {

	private _commentsImagesRef: firebase.database.Reference;
	private _commentsRef = this.db.database.ref(`comments`);
	private _commentItemRef = this.db.database.ref(`comment-items`);
	private _discussionItemRef = this.db.database.ref(`discussion-items`);
	private _postItemRef = this.db.database.ref(`posts`);

	constructor(private db: AngularFireDatabase, 
		private _accountSerivce: AccountService,
		private afAuth: AngularFireAuth,
		private notificationsService: NotificationsService) {
			this._commentsImagesRef = this.db.database.ref(`comment-images`);
		}

	getCommentKeys(parentKey: string, source: string) {
		if (source == 'Alert') {					
			return this.db.list(this._commentsRef.child(parentKey),
			queryFN => queryFN.orderByChild('sortSequence'))
			.snapshotChanges()
			.pipe(map(refs => refs.map(ref => ref.key)));			
		}
		else {
			return this.db.list(this._commentsRef.child(parentKey))			
			.snapshotChanges()
			.pipe(map(refs => refs.map(ref => ref.key)));
		}		
	}

	// getUsersWhoHaveCommented(parentKey: string, obj, objArr)
	// {
	// 	return this._commentsRef.child(parentKey).once('value').then(function(snapshot) {
	// 		snapshot.forEach(keys => {

	// 			obj = keys.toJSON();				
	// 			var found = false;
	// 			if (objArr != undefined) {
	// 				var x = 0;
	// 				for (x = 0; x < objArr.length; x++) {
	// 					if (objArr[x].createdBy == obj.createdBy) {
	// 						found = true;
	// 					}
	// 				}	
	// 			}

	// 			if (!found) {												
	// 				objArr.push(obj);
	// 			}							
	// 		})
	// 	})
			
	// }

	getCommentCount(parentKey: string, obj, prevObj) {
		return this.db.list(this._commentsRef.child(parentKey))
			.snapshotChanges().forEach(snapshot=> {
				snapshot.forEach(keys => {
					obj.count++;
				})			
				obj.count = obj.count - prevObj.count;
				if (obj.count < 0)
					obj.count = 0;
				prevObj.count = obj.count;
			});
	}

	getComment(key: string) {
		return this.db.object(this._commentItemRef.child(key));
	}

	delete(parentKey: string, key: string) {
		return this.db.object(this._commentsRef.child(parentKey).child(key)).remove();
	}

	create(parentKey: string, text: string, category: string, albumKey: string) {
	
		let comment = {
			text: text,
			createdBy: this.afAuth.auth.currentUser.uid,
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			createdByName: this.afAuth.auth.currentUser.displayName ,
			albumKey: albumKey,
			sortSequence: 0 - Date.now()
		};

		let ref = this.db.list(this._commentItemRef).push(comment);

		if (category != 'post') {  // discussion items
			this.db.object(this._discussionItemRef.child(parentKey))
			.valueChanges().pipe(take(1)).subscribe(record => {
				
				var nameArr = [];
				var objects = []
				var obj = new Object(); //record;
				obj = record;
				objects.push(obj);
				nameArr.push(objects[0].createdBy);				
				this.notificationsService.create(
					text,
					parentKey,
					this.afAuth.auth.currentUser.photoURL,
					['neighborhood/discussion', {outlets: {modal: ['view', parentKey, objects[0].category, '','']}}],
					nameArr,
					this.afAuth.auth.currentUser.displayName
				);
			})
		}
		else {  // alerts
			this.db.object(this._postItemRef.child(parentKey))
			.valueChanges().pipe(take(1)).subscribe(record => {				
				var nameArr = [];
				var objects = []
				var obj = new Object(); //record;
				obj = record;
				objects.push(obj);
				nameArr.push(objects[0].createdBy);				
				this.notificationsService.create(
					text,
					ref.key,
					this.afAuth.auth.currentUser.photoURL,
					['/neighborhood/alerts', { outlets: { modal: ['view', parentKey] } }],
					nameArr,
					this.afAuth.auth.currentUser.displayName
				);
			})
		}
							
		this.db.object(this._commentsRef.child(parentKey).child(ref.key)).set(comment);
		//console.log('Why is this getting set twice? ' + parentKey + ' ' + ref.key + ' ' +  comment);
		
		return ref;
	}

	editComment(text: string, key: string) {
		return this.db.object(this._commentItemRef.child(key).child('text')).set(text);
	}
	
	getAlbumImages() {
		return Observable.create(observer => {
			this._accountSerivce.currentSiteKey.subscribe(siteKey => {
				this.db.list(this._commentsImagesRef.child(siteKey),
					queryFN => queryFN.limitToLast(5)
				).valueChanges().subscribe(images => observer.next(images));
			});
		});
	}
}
