import { ServicesModule } from './services/services.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        ServicesModule
    ],
    declarations: []
})
export class CoreModule { }
