import { TutorialModule } from './tutorial/tutorial.module';
import { ContactModule } from './contact/contact.module';
import { FeaturesModule } from './features/features.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { MarketingRoutingModule } from './marketing-routing.module';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { HOAComponent } from './hoa/hoa.component';
import { FeaturesComponent } from './features/features.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { FaqComponent } from './faq/faq.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SignupLaterComponent } from './signup-later/signup-later.component';
import { JoinComponent } from './join/join.component'
import { TooltipModule } from 'ngx-bootstrap';

@NgModule({
    imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
        MarketingRoutingModule,
        FeaturesModule,
        ContactModule,
        TutorialModule,
        TooltipModule.forRoot()
    ],
    declarations: [JoinComponent, SignupLaterComponent, HomeComponent, AboutComponent, HOAComponent, FeaturesComponent, TutorialComponent, ContactComponent, FooterComponent, FaqComponent, TosComponent, PrivacyPolicyComponent]
})
export class MarketingModule { }
