import { UploaderComponent } from 'app/files/uploader/uploader.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DiscussionService } from "app/discussion/discussion.service";
import { GroupSelectorComponent } from "app/groups/group-selector/group-selector.component";
import { SitesService } from "app/core/services/sites.service";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-discussion-create',
	templateUrl: './discussion-create.component.html',
	styleUrls: ['./discussion-create.component.css']
})
export class DiscussionCreateComponent implements OnInit {

	@ViewChild(GroupSelectorComponent) groupSelector: GroupSelectorComponent;
    @ViewChild(UploaderComponent) uploader: UploaderComponent;
    
	text = '';
    title = '';
    price = '';
    startDate = '';
    startTime = '';
    endDate = '';
    endTime = '';
    category: string = 'discussion board';
	editing = false;
    tags = [];
    
    memberCountObj = {count: 0};
    groupTypeObj = {status: '', name: ''};
    key;
    isGroup = false;
    bsValueStartDate = new Date();
    bsValueStartTime = new Date();   
    
    bsValueEndDate = new Date();
    bsValueEndTime = new Date(); 

    sendNotificationFlag = false;
    postForm: FormGroup;

    constructor(private discussionService: DiscussionService,
                private sitesService: SitesService,
                private formBuilder: FormBuilder) { }

	ngOnInit() {
        this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);});
        this.discussionService.currentMessage.subscribe(message => {this.changeCategory(message);});
        this.sendNotificationFlag = false;
        this.postForm = this.formBuilder.group({
            text: '',
            title: '',
            price: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
          });
	}

	submit() {        
        this.tags.push(this.category);
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };                
        this.discussionService.createPost(this.title, this.text, this.price, this.bsValueStartDate.toLocaleDateString("en-US", options), this.bsValueStartTime.toLocaleTimeString("en-US"), this.bsValueEndDate.toLocaleDateString("en-US", options), this.bsValueEndTime.toLocaleTimeString("en-US"), this.groupSelector.selectedGroupKey, this.category, this.tags, this.uploader.albumKey, this.sendNotificationFlag);        
        this.reset();        
        this.postForm.reset();
        this.sendNotificationFlag = false;
        this.text = '';
        this.title = '';
    }

    setEditMode() {        
        this.editing = true;
    }
    
    cancel() {
        this.editing = false;
        this.reset();
    }

    reset() {        
        this.editing = false;
		//this.uploader.clear();
		this.tags = [];
		window.scrollTo(0,0);
    }

    selectNeighborhood(key) {
        this.key = key;	
        let promises = Promise.all(
			[this.sitesService.getCurrentMemberCount(this.key, this.memberCountObj, this.groupTypeObj)]
		);

		promises.then((result) => {	
			if (this.groupTypeObj.status == 'small' || 
				this.groupTypeObj.status == 'medium' || 
				this.groupTypeObj.status == 'large')				
				this.isGroup = false;
            else {
                this.category = 'discussion board';
                this.isGroup = true;
            }
				
		});
    }

    changeCategory(message) {           
        this.category = message;
    }

    sendNotificationEmail() {
        this.sendNotificationFlag = true;
        //console.log('Send Notification', this.sendNotificationFlag);
    }

    doNotSendNotificationEmail() {
        this.sendNotificationFlag = false;
        //console.log('Do not send Notification', this.sendNotificationFlag);
    }
}
