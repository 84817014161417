import { TutorialSigninComponent } from './tutorial-signin/tutorial-signin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorialComponent } from "app/marketing/tutorial/tutorial.component";
import { TutorialForgotPasswordComponent } from "app/marketing/tutorial/tutorial-forgot-password/tutorial-forgot-password.component";
import { TutorialProfileComponent } from "app/marketing/tutorial/tutorial-profile/tutorial-profile.component";
import { TutorialNotificationsComponent } from "app/marketing/tutorial/tutorial-notifications/tutorial-notifications.component";
import { TutorialAlertsComponent } from "app/marketing/tutorial/tutorial-alerts/tutorial-alerts.component";
import { TutorialMapsComponent } from "app/marketing/tutorial/tutorial-maps/tutorial-maps.component";
import { TutorialDiscussionBoardComponent } from "app/marketing/tutorial/tutorial-discussion-board/tutorial-discussion-board.component";
import { TutorialMembersComponent } from "app/marketing/tutorial/tutorial-members/tutorial-members.component";
import { TutorialTagsComponent } from "app/marketing/tutorial/tutorial-tags/tutorial-tags.component";
import { TutorialProfileSetupComponent } from './tutorial-profile-setup/tutorial-profile-setup.component';
import { TutorialTestAlertComponent } from './tutorial-test-alert/tutorial-test-alert.component';
import { TutorialStartGroupComponent } from './tutorial-start-group/tutorial-start-group.component';
import { TutorialSendAlertComponent } from './tutorial-send-alert/tutorial-send-alert.component';
import { TutorialWelcomeComponent } from './tutorial-welcome/tutorial-welcome.component';
import { TutorialChangeGroupComponent } from './tutorial-change-group/tutorial-change-group.component';
import { TutorialRingToneComponent } from './tutorial-ring-tone/tutorial-ring-tone.component'

const routes: Routes = [
    {
        path: 'marketing/tutorial', component: TutorialComponent,
        children: [
            { path: 'signin', component: TutorialSigninComponent },
            { path: 'changegroup', component: TutorialChangeGroupComponent},
            { path: 'forgot-password', component: TutorialForgotPasswordComponent },
            { path: 'profile', component: TutorialProfileComponent },
            { path: 'profile-setup', component: TutorialProfileSetupComponent },
            { path: 'notifications', component: TutorialNotificationsComponent },
            { path: 'alerts', component: TutorialAlertsComponent },
            { path: 'maps', component: TutorialMapsComponent },
            { path: 'discussion-board', component: TutorialDiscussionBoardComponent },
            { path: 'members', component: TutorialMembersComponent },
            { path: 'tags', component: TutorialTagsComponent  },
            { path: 'test-alert', component: TutorialTestAlertComponent },
            { path: 'ring-tone', component: TutorialRingToneComponent},
            { path: 'start-group', component: TutorialStartGroupComponent },
            { path: 'send-alert', component: TutorialSendAlertComponent },
            { path: 'welcome', component: TutorialWelcomeComponent}
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorialRoutingModule { }
