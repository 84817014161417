import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { trigger, state, animate, transition, style } from '@angular/animations';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-tutorial',
	templateUrl: './tutorial.component.html',
	styleUrls: ['./tutorial.component.css'],
	animations: [
		trigger('heroState', [
			state('false', style({
				transform: 'scale(1)'
			})),
			state('true', style({
				transform: 'scale(0)'
			})),
            transition('inactive => active', animate('100ms ease-in')),
			transition('active => inactive', animate('100ms ease-out'))
		])
	]
})
export class TutorialComponent implements OnInit {

	categories = [
		{ icon: 'icon-alerts', title: 'Welcome', image: 'assets/images/faq-sign-in.jpg', route: 'welcome' },
		{ icon: 'fa-address-book', title: 'Profile Setup', image: 'assets/images/faq-pw.jpg', route: 'profile-setup' },
		{ icon: 'fa-check-square-o', title: 'Test Alert', image: 'assets/images/faq-pw.jpg', route: 'test-alert' },
		{ icon: 'fa-cog', title: 'Profile Settings', image: 'assets/images/faq-profile.jpg', route: 'profile' },
		{ icon: 'fa-user-plus', title: 'Start a Group', image: 'assets/images/faq-profile.jpg', route: 'start-group' },
		{ icon: 'fa-bullhorn', title: 'Send an Alert', image: 'assets/images/faq-profile.jpg', route: 'send-alert' },
		{ icon: 'fa-unlock-alt', title: 'Forgot Password', image: 'assets/images/faq-pw.jpg', route: 'forgot-password' },
		{ icon: 'fa-exclamation', title: 'Notifications', image: 'assets/images/faq-notifications.jpg', route: 'notifications' },
		{ icon: 'fa-bell', title: 'Alerts', image: 'assets/images/faq-alert.jpg', route: 'alerts' },
		{ icon: 'fa-sign-out', title: 'Change Group', image: 'assets/images/faq-sign-in.jpg', route: 'changegroup' },
		{ icon: 'fa-map', title: 'Map', image: 'assets/images/faq-map.jpg', route: 'maps' },
		{ icon: 'fa-thumb-tack', title: 'Discussion Board', image: 'assets/images/faq-forum.jpg', route: 'discussion-board' },
		{ icon: 'fa-users', title: 'Members', image: 'assets/images/faq-member.jpg', route: 'members' },
		{ icon: 'fa-phone', title: 'Ring Tone', image: 'assets/images/faq-member.jpg', route: 'ring-tone' },
	
	];

	routeActivated = false;
	inChildRoute = false;

	constructor(private activatedRoute: ActivatedRoute,private titleService: Title) { 
		titleService.setTitle('Wissle Tutorial');
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			//console.log(params)
		});

	}

	onActivate(e) {
		this.routeActivated = true;
	}

	onDeactivate(e) {
		this.routeActivated = false;
	}

    animationDone(e) {
        //console.log('done', e);
		this.inChildRoute = e.toState == 1 ? true: false;
    }
    
    animationStarted(e) {
        //console.log('start',e);
    }
}
