import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PostsService } from "app/posts/posts.service";

@Component({
	selector: 'app-post-types-selector',
	templateUrl: './post-types-selector.component.html',
	styleUrls: ['./post-types-selector.component.css']
})
export class PostTypesSelectorComponent implements OnInit {

	postTypes: any;

    selectedPostType: any;
    
    finishedLoading = new EventEmitter<boolean>();

	@Output() postTypeSelected = new EventEmitter();

	constructor(private postsService: PostsService) { }

	ngOnInit() {
		this.postTypes = this.postsService.getPostTypes().valueChanges();

		this.postTypes.subscribe(postTypes => {
			this.selectedPostType = postTypes[0];
            this.selected(this.selectedPostType);
            this.finishedLoading.next(true);
		});
	}

	selected(postType) {
		//console.log(postType, 't')
		this.selectedPostType = postType;
		this.postTypeSelected.emit(postType);
    }

}
