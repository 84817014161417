import { SitesService } from 'app/core/services/sites.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GroupsService } from "app/groups/groups.service";
import { AccountService } from "app/core/services/account.service";

@Component({
    selector: 'app-groups-create',
    templateUrl: './groups-create.component.html',
    styleUrls: ['./groups-create.component.css']
})
export class GroupsCreateComponent implements OnInit {

    @Output() cancelled = new EventEmitter<boolean>();
    @Output() created = new EventEmitter<boolean>();

    name: string;
    members: { key: string, selected: boolean }[] = [];

    constructor(private groupsService: GroupsService,
        private accountService: AccountService,
        private sitesService: SitesService) { }

    ngOnInit() {
        this.accountService.currentSiteKey.subscribe(siteKey => {
            this.sitesService.getMemberKeys(siteKey)
                .subscribe(memberKeys => {
                    this.members = [];
                    memberKeys.forEach(key => {
                        this.members.push({
                            key: key,
                            selected: false
                        })
                    });
                });
        });
    }

    submit() {
        this.groupsService.createGroup(this.name, this.members
            .filter((value, index) => value.selected).map(value => value.key));
        this.created.next(true);
        this.clear();
    }
    
    cancel() {
        this.cancelled.next(true);
        this.clear();
    }

    clear() {
        this.name = '';
        this.members.forEach(member => { member.selected = false });
    }
}
