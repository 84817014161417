import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from 'firebase';
import { SitesService } from 'app/core/services/sites.service';
import { Http, Headers, RequestOptions } from '@angular/http';


@Injectable()
export class NotificationsService {

	private __notificationRef = this.db.database.ref('user-notifications');
	private site_invitation = this.db.database.ref(`site-invitation`);

	constructor(private db: AngularFireDatabase, 
		private afAuth: AngularFireAuth,
		private siteServices: SitesService,
		public http: Http) { }

	removeNotification(groupKey, createdBy, ) {
	
		this.db.list(this.__notificationRef.child(this.afAuth.auth.currentUser.uid).child('feed').child(groupKey)).remove();
		return this.db.list(this.site_invitation.child(groupKey).child(createdBy).child(this.afAuth.auth.currentUser.uid)).remove();		
	}

	hasNewNotifications() {
		return this.db.object(this.__notificationRef.child(this.afAuth.auth.currentUser.uid).child('has-new'));
	}

	clearNewNotifications() {
		this.db.object(this.__notificationRef.child(this.afAuth.auth.currentUser.uid).child('has-new')).set(false);
	}

	setNotificationAsRead(key: string) {
		this.db.object(this.__notificationRef.child(this.afAuth.auth.currentUser.uid).child('feed').child(key).child('isRead')).set(true);
	}

	getNotifications() {
		return this.db.list(this.__notificationRef.child(this.afAuth.auth.currentUser.uid)
			.child('feed'), queryFN => queryFN.limitToLast(5));
	}

	create(text: string, key: string, imageURL: string, action: any, recipientKeys: string[], displayName: string) {
		let toUpdate = {};
	
		recipientKeys.forEach(userKey => {
			if (userKey != this.afAuth.auth.currentUser.uid) {
				toUpdate[`${userKey}/feed/${key}`] = {
					text: text,
					imageURL: imageURL,
					action: action,
					isRead: false,
					createdBy: this.afAuth.auth.currentUser.uid,
                    createdOn: firebase.database.ServerValue.TIMESTAMP,
                    displayName: displayName
				};

				toUpdate[`${userKey}/has-new`] = true;
			}
		});

		this.db.object(this.__notificationRef).update(toUpdate);
	}

	//create notification when adding an existing member
    createNotification(text: string, key: string, imageURL: string, action: any, userKey) {
		
		//console.log('action');
		//console.log(userKey);
		//console.log(action);
        let toUpdate = {};
        toUpdate[`${userKey}/feed/${key}`] = {
            text: text,
            imageURL: imageURL,
            action: action,
            isRead: false,
            createdBy: this.afAuth.auth.currentUser.uid,
            createdOn: firebase.database.ServerValue.TIMESTAMP
        };
        toUpdate[`${userKey}/has-new`] = true;

        // this.hasNewNotifications().subscribe(res=>{
        // //console.log(res)
        // }),err=>{
        // //console.log(err);
        // }
        this.__notificationRef.update(toUpdate);
    }

	afterSignUpNotification(site,title,msg,uid,invited_site_key,createdBy,siteType){
	
	    localStorage.removeItem('value');
        var headers = new Headers();
        headers.append("Accept", 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'key=AAAAqajSIH0:APA91bHotfYXSRkeqCyViMP-ZGXtKAoRNg_BfScoEBpk2GXgk1_26ahtgC5whZOuY90dMxQwQmxeENRhDX3n02phq0EYdYWWWxCWVKoV3di5L2TIEL1fCVFMnJkFWJplDMgo-Lh4pDgL');
        let options = new RequestOptions({ headers: headers });
        let postParams = {
            // "data": {
            //   "userId": "barId",
            //   "message":"key"
            // },
            "notification": {
                "body": msg,
                "title": title
            },
            "priority": "high",
            "to": `/topics/${uid}`
        }
        let _this = this;
        _this.http.post("https://fcm.googleapis.com/fcm/send", postParams, options)
            .subscribe(data => {

               
                if (invited_site_key) {
					//console.log(invited_site_key);
                    _this.createSignupNotification(`${msg}`, invited_site_key, site.photoURL != null ? site.photoURL : '',
                    '/neighborhood/members',uid,createdBy,siteType)
						//console.log('notice');
                    if (site.siteType == 'private') {
                        _this.addPrivateMember(invited_site_key);
                    }
                }
            }, error => {
                //console.log(error);// Error getting the data
            });
	
	}
	createSignupNotification(text: string, key: string, imageURL: string, action: any, userKey,createdByid : string,siteType) {
		
		//console.log('action');
		//console.log(userKey);
		//console.log(action);
        let toUpdate = {};
        toUpdate[`${userKey}/feed/${key}`] = {
            text: text,
            imageURL: imageURL,
            action: action,
            isRead: false,
            createdBy: createdByid,
            createdOn: firebase.database.ServerValue.TIMESTAMP,
			siteType:siteType
        };
        toUpdate[`${userKey}/has-new`] = true;

        // this.hasNewNotifications().subscribe(res=>{
        // //console.log(res)
        // }),err=>{
        // //console.log(err);
        // }
        this.__notificationRef.update(toUpdate);
	}
	
	addPrivateMember(siteKey) {
        return this.siteServices.addMemberToPrivateSite(siteKey);
	}
	

    /*
    createDiscussion(text: string, key: string, memberKeys: string[]) {
        this.create(
            text ? text : 'New discussion post added',
            key,
            this.afAuth.auth.currentUser.photoURL,
            ['/neighborhood/alerts', { outlets: { modal: ['view', key] } }],
            memberKeys);
    }*/
}
