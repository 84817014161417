import { AccountService } from 'app/core/services/account.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { Component, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as firebase from 'firebase';
import { AngularFireDatabase } from 'angularfire2/database';
declare var google: any;

@Component({
    selector: 'app-complete-profile',
    templateUrl: './complete-profile.component.html',
    styleUrls: ['./complete-profile.component.css']
})
export class CompleteProfileComponent implements OnInit, AfterViewInit {

    @ViewChild(ModalComponent) modal: ModalComponent;

    private _peopleRef = this.db.database.ref('people');
    private geocoder: any;

    data: any;
    file: any;
    
    address = '';
    googleLoaded = false;
    showMap = false;
    latitude = 0;
    longitude = 0;
    zoom = 18;
    cellPhone = '';
    saving = false;

    element: HTMLInputElement;

    prevNeighborhoodUrl = '/';
    addressValid = false;

    imageChangedEvent: any = '';
	croppedImage: any = '';
    imageFileName: any = '';
    
    fileUploadTask = null;
    
    fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;	
		var image:any = new Image();					
		this.file = event.target.files[0];
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;								
			that.data.image = image.src;						
		};
		myReader.readAsDataURL(this.file);
    }
    
    
	
    imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}
	
    imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.data.image = image;	
	}
	
    cropperReady() {
		// cropper ready
	}
	
    loadImageFailed() {
		// show message
    }

    constructor(private accountService: AccountService,        
        private _ngZone: NgZone,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private db: AngularFireDatabase) {
        titleService.setTitle('Wissle Alert');
        

        this.data = {};
    }

    ngOnInit() {
        this.prevNeighborhoodUrl = this.route.snapshot.queryParams['prevNeighborhoodUrl'] || '/'; 
         
        this.accountService.getUserDetails()
			.valueChanges()
			.subscribe((details: any) => {                
				this.cellPhone = details.phoneNumber;				
			});
                
        this.initMap();	
        this.saving = true;	
    }

    ngAfterContentInit() {
        
        
    }

    initMap() {	
        	  
      this.element = <HTMLInputElement>document.getElementById('pac-input');	
      var placesToFind = new google.maps.places.SearchBox(this.element);	 
          // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      let that = this;
      //let addressaddress = this.address;
      //var myplace;
      placesToFind.addListener('places_changed', function() {          
        var places = placesToFind.getPlaces();
debugger;
        if (places.length == 0) {
          return;
        }
debugger;
		  //myplace = thisthis.getFormattedAddress(places[0]);   
		    that.address = places[0].formatted_address;       
        that.getAddress(places[0]);
		});
                  		
	}

    ngAfterViewInit() {
        this.accountService.profileCompleted
            .subscribe(isComplete => {
                //console.log(isComplete)
                if (isComplete) {
                    this.modal.hide();
                } else {
                    this.modal.show();
                }
            });
    }

    getAddress(e) {
        //console.log(e)
        this.geocoder = new google.maps.Geocoder;
        this.address = e.formatted_address;
        this.geocoder.geocode({ 'address': e.formatted_address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                this._ngZone.run(() => {
                    var i;
                    for (i = 0; i < results[0].types.length; i++)
                    {
                        if (results[0].types[i] == 'street_address' ||
							results[0].types[i] == 'premise')
                        {
                            ;
                            this.addressValid = true;
                            break;
                        }
                    }
                    this.latitude = results[0].geometry.location.lat();
                    this.longitude = results[0].geometry.location.lng();
                    this.showMap = true;
                    this.saving = false;
                });
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    markerDragEnd(e) {
        this.latitude = e.coords.lat;
        this.longitude = e.coords.lng;
    }

    addressChange($event) {
		this.addressValid = false;
    }
    
    updateProfileImage3(file: File, data: string) {
		data = data.replace(`data:image/png;base64,`, '');
		let storage = firebase.storage();
		let storageRef = storage.ref();
		let fileStorageRef = storageRef.child('profile-images').child(this.accountService.currentUser.uid);
		this.fileUploadTask = fileStorageRef.putString(
			data,
			'base64',
			{ customMetadata: { updatePath: `people/${this.accountService.currentUser.uid}/profileImage` }, cacheControl: 'max-age=31536000', contentType: file.type });

		console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);

		this.fileUploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
		function(snapshot) {
			//debugger;
		  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
		  var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		  console.log('Upload is ' + progress + '% done');
		//   this.zone.run(() => {
		// 	this.uploadProgress = progress;
		//   });
		  
		  switch (snapshot.state) {
			case firebase.storage.TaskState.PAUSED: // or 'paused'
			  console.log('Upload is paused');
			  break;
			case firebase.storage.TaskState.RUNNING: // or 'running'
			  console.log('Upload is running');
			  break;
		  }
		}, function(error) {
	  
		// A full list of error codes is available at
		// https://firebase.google.com/docs/storage/web/handle-errors
		switch (error.message) {
		  case 'storage/unauthorized':
			// User doesn't have permission to access the object
			break;
	  
		  case 'storage/canceled':
			// User canceled the upload
			break;
	  
		  case 'storage/unknown':
			// Unknown error occurred, inspect error.serverResponse
			break;
		}
	   
	  });

		return this.fileUploadTask
			.then(snapshot =>   {				
				return snapshot.task.then(uploadTask => {
					console.log("bytes transferred: " + this.fileUploadTask.snapshot.bytesTransferred);
					return uploadTask.ref.getDownloadURL().then(downloadURL => {
						return this.db.object(this._peopleRef.child(this.accountService.currentUser.uid).child('profileImage'))
							.update({
								downloadURL: downloadURL,
								downloadURLOriginal: downloadURL
							});
					});
				});
			});
	}


    save() {
        if (this.addressValid == false) {				
			alert('Your address is invalid. Please type in your address again and select from the results.');
			return;
		}
        this.saving = true;
        let promises = [];

        if (this.address.length) {
            promises.push(this.accountService.updateAddress(this.address, this.latitude, this.longitude));
        }

        if (this.cellPhone.length) {
            promises.push(this.accountService.updateCellPhone(this.cellPhone));
        }
        
        if (this.data.image) {  
            debugger;          
             promises.push(this.updateProfileImage3(this.file, this.data.image));
        }

        Promise.all(promises)
            .then(() => {
                this.accountService.setProfileCompleted(this.accountService.currentUser.uid, true);
                debugger;
                this.saving = false;
                if (this.prevNeighborhoodUrl != '/')
                    this.router.navigate([this.prevNeighborhoodUrl]);
                
            }, (error) => {
                debugger;
                alert(error);                
                this.saving = false;
            });
    }
}
