import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap";

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    exportAs: "modal"
})
export class ModalComponent implements OnInit {

    @Input() hideTitle = false;
    @Input() modalSize = '';
    @Input() hasFloatingClose = false;
    @Input() hideCloseButton = false;
    @Input() config = { backdrop: 'static' };
    @Input() appendToBody = false;

    @Output() onHiding = new EventEmitter();

    @ViewChild(ModalDirective) modal: ModalDirective;

    @ViewChild('header') header: ElementRef;
    @ViewChild('footer') footer: ElementRef;

    headerIsVisible = true;
    footerIsVisible = false;

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        this.headerIsVisible = this.header.nativeElement.children.length > 0 && this.header.nativeElement.children[0].className == 'header';
        this.footerIsVisible = this.footer.nativeElement.children.length > 0 && this.footer.nativeElement.children[0].className == 'footer';

        if (this.appendToBody) {
            document.body.appendChild(this.elementRef.nativeElement);
        }

        this.modal.onShown.subscribe(() => {
            this.headerIsVisible = this.header.nativeElement.children.length > 0 && this.header.nativeElement.children[0].className == 'header';
            this.footerIsVisible = this.footer.nativeElement.children.length > 0 && this.footer.nativeElement.children[0].className == 'footer';
        });
    }

    show() {
        this.modal.show();
    }

    hide() {
        this.modal.hide();
        this.onHiding.emit();
    }
}
