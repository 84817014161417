import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { SitesService } from 'app/core/services/sites.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageComponent } from './home-page/home-page.component';
import { NoSitePageComponent } from './no-site-page/no-site-page.component';
import { PostsModule } from "app/posts/posts.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PostsModule,
        AgmCoreModule.forRoot()
    ],
    declarations: [HomePageComponent, NoSitePageComponent]
})
export class PagesModule { }
