
import {map, take, mergeMap} from 'rxjs/operators';
import { BehaviorSubject ,  ReplaySubject ,  Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';
import { Md5 } from 'ts-md5/dist/md5';



//export enum SubscriptionTypes { App, Neighborhood, Board, ChatRoom }
export enum MemberTypes { member, administrator }
export enum GroupTypes { app, site, group, chats }
export enum MessageTypes { alerts, discussion, comment }

enum ObjectTypes { messages, people, groups, chatitem }

@Injectable()
export class AppEngineService {

	private _isSiteAdministrator = false;

	get auth() {
		return this.db.database.app.auth();
	}

	get uid() {
		return this.auth.currentUser.uid;
	}

	get authState() {
		return this.afAuth.authState;
	}

	get isSiteAdministrator() {
		return this._isSiteAdministrator;
	}

	constructor(protected db: AngularFireDatabase, private afAuth: AngularFireAuth) {

		afAuth.authState.subscribe(user => {
			if (user) {
				const connectedRef = this.db.object(`user-status/${user.uid}/connected`).query.ref;
				const lastOnlineRef = this.db.object(`user-status/${user.uid}/lastOnline`).query.ref;
				this.db.object('.info/connected')
					.snapshotChanges()
					.subscribe(snap => {
						connectedRef.set(true)
						connectedRef.onDisconnect().set(false);
						lastOnlineRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
					});

				this.db.object(`site-admins/${user.uid}`)
					.snapshotChanges()
					.subscribe(isAdmin => {
						this._isSiteAdministrator = <boolean>isAdmin.payload.val() || false;
					});
			}
		});

	}

	get isAuthenticated() {
		return this.authState.pipe(
			map(user => user != null));
	}
	get currentUser() {
		const peopleRef = this.db.database.ref('people');
		return {

			getUserInfo: () => {
				return this.db.object(peopleRef.child(this.uid));
			},
			hasUserCompletedSetup: () => {
				return this.db.object(peopleRef.child(this.uid).child('completedSetup'))
					.snapshotChanges().pipe(
					map(ref => ref.payload.exists()));
			},
			completeSetup: () => {
				this.db.object(peopleRef.child(this.uid)).update({
					completedSetup: true
				});
			},
			initialize: (email: string, siteKey: string, token: string) => {
				this.sites.getInvite(email, token, siteKey)
					.then(info => {
						this.currentUser.updateProfile(info.firstName, info.lastName, null);
						this.currentUser.updateAddress(info.address);
					});
			},
			updateAddress: (address: string, latitude: number = null, longitude: number = null) => {
				this.db.object(peopleRef.child(this.uid)).update({
					address: address,
					latitude: latitude,
					longitude: longitude
				});
			},
			updateInitialsProfileImage: (imageString: string) => {
				this.db.object(peopleRef.child(this.uid)).update({
					initialsImage: imageString
				})
			},
			updateProfile: (firstName: string, lastName: string, photoURL: string) => {
				this.db.database.app.auth().currentUser.updateProfile({
					displayName: `${firstName} ${lastName}`,
					photoURL: photoURL
				}).then(resolve => {
					this.db.object(peopleRef.child(this.uid)).update({
                        /*photoURL: photoURL,
                        photoURLsm: photoURL,
                        photoURLthumb: photoURL,*/
						name: `${firstName} ${lastName}`,
						firstName: firstName,
						lastName: lastName
					});
				});
			}
		}
	}

	get people() {
		const peopleRef = this.db.database.ref('people');
		return {
			getUserDetails: (uid: string) => {
				return this.db.object(peopleRef.child(uid));
			},
			generateInitalsAvatar: (options: any = {}) => {
				var settings = Object.assign({
					initials: '',
					initial_fg: '#888888',
					initial_bg: '#f4f6f7',
					initial_size: null,
					initial_weight: 100,
					initial_font_family: "'Lato', 'Lato-Regular', 'Helvetica Neue'",
					hash: null,
					size: 80,
					fallbackImage: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj48cGF0aCBmaWxsPSIjYmNjN2NlIiBkPSJNMCAwaDYwdjYwaC02MHoiLz48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiNhNGIxYjkiIGQ9Ik0zMC4xIDU0LjhjLTYuNyAwLTEzLjEtMi44LTE3LjYtNy43bC0uNS0uNXYtMi42aC4yYy40LTQgMS42LTYuNyAzLjQtNy42IDEuMy0uNiAyLjktMS4xIDQuOS0xLjZ2LTFsMS0uM3MuNy0uMiAxLjctLjVjMC0uNS0uMS0uNy0uMS0uOS0uNi0xLTEuNS0zLjMtMi4xLTZsLTEuNy0xLjQuMi0uOXMuMi0uOSAwLTEuOWMtLjItLjkuMS0xLjUuMy0xLjguMy0uMy42LS41IDEtLjYuMy0xLjYuOS0zLjEgMS43LTQuMy0xLjMtMS41LTEuNy0yLjYtMS41LTMuNS4yLS45IDEtMS41IDEuOS0xLjUuNyAwIDEuMy4zIDEuOS42LjMtLjcuOS0xLjEgMS43LTEuMS43IDAgMS40LjQgMi40LjguNS4zIDEuMi42IDEuNi43IDMuNC4xIDcuNiAyLjIgOC45IDcuNi4zLjEuNi4zLjguNS40LjUuNSAxLjEuMyAxLjktLjIgMS4yIDAgMi40IDAgMi40bC4yLjgtMS4yIDEuMmMtLjUgMi44LTEuNiA1LjQtMi4yIDYuNS0uMS4xLS4xLjQtLjEuOCAxIC4zIDEuNy41IDEuNy41bDEgLjR2LjhjMi41LjUgNC42IDEuMSA2LjEgMS45IDEuOC45IDIuOSAzLjUgMy40IDcuOGwuMS42LS40LjVjLTQuNiA1LjktMTEuNSA5LjQtMTkgOS40eiIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik00NS40IDM2LjhjLTEuNS0uOC0zLjktMS41LTctMnYtLjlzLTEtLjQtMi42LS43Yy0uMi0uOC0uMy0yIC4yLTIuOC41LS45IDEuNy0zLjYgMi4xLTYuNWwuOS0uOXMtLjMtMS40IDAtM2MuMi0uOS0uNC0uNy0uOS0uNS0uOS03LjEtNi4zLTcuNy03LjgtNy43LTEuNC0uMi0zLjktMi4yLTQuMS0xLjMtLjEuOSAxLjIgMS44LS40IDEuNC0xLjYtLjQtMy4xLTEuOC0zLjMtLjgtLjIuNyAxLjIgMi4zIDIgMy4xLTEuMiAxLjMtMi4xIDMuMi0yLjQgNi4xLS41LS4zLTEuNC0uNy0xLjEuMi4zIDEuMyAwIDIuNiAwIDIuNmwxLjQgMS4yYy41IDIuNyAxLjUgNS4xIDIgNiAuNS44LjMgMi4xLjIgMi44LTEuNS4zLTIuNi43LTIuNi43djEuMmMtMi41LjUtNC40IDEuMS01LjggMS43LTIgMS0yLjYgNS43LTIuNyA3Ljl2LjRjNC4xIDQuNCAxMCA3LjIgMTYuNSA3LjIgNy4zIDAgMTMuNy0zLjUgMTcuOC04LjgtLjEtMi4zLS44LTUuNy0yLjQtNi42eiIvPjwvZz48L3N2Zz4=',
				}, options);

				var canvas = document.createElement('canvas');
				var context = canvas.getContext('2d');
				var width = 100;// element.width || settings.size;
				var height = 100; //element.height || settings.size;
				var devicePixelRatio = Math.max(window.devicePixelRatio, 1);
				canvas.width = width * devicePixelRatio;
				canvas.height = height * devicePixelRatio;
				canvas.style.width = width + 'px';
				canvas.style.height = height + 'px';
				context.scale(devicePixelRatio, devicePixelRatio);
				context.rect(0, 0, canvas.width, canvas.height);
				context.fillStyle = settings.initial_bg;
				context.fill();
				context.font = settings.initial_weight + ' ' + (settings.initial_size || height / 2) + 'px ' + settings.initial_font_family;
				context.textAlign = 'center';
				context.textBaseline = 'middle';
				context.fillStyle = settings.initial_fg;
				context.fillText(settings.initials, width / 2, height / 2);

				return canvas.toDataURL('image/png');
			}
		}
	}

	get sites() {
		const sitesRef = this.db.database.ref('sites');
		const userSitesRef = this.db.database.ref('user-sites');
		const siteUsersRef = this.db.database.ref('site-users');
		const currentSiteRef = this.db.database.ref('user-current-site');
		const siteInvitationsRef = this.db.database.ref('site-invitations')
		return {
			getSiteByKey: (key: string) => {
				return this.db.object(sitesRef.child(key));
			},
			getMySites: () => {
				return this.db.list(userSitesRef.child(this.uid)).snapshotChanges()
			},
			addMember: (uid: string, siteKey: string, memberTypeKey: string) => {
				return Promise.all([
					this.db.object(userSitesRef.child(uid).child(siteKey).child(memberTypeKey)).set(true),
					this.db.object(siteUsersRef.child(siteKey).child(uid).child(memberTypeKey)).set(true)
				]);
			},
			getPreviousInvite: (email: string) => {
				const emailHash = Md5.hashAsciiStr(email).toString();
				return this.sites.getCurrentSiteKey()
					.snapshotChanges().pipe(
					mergeMap(siteRef => {
						return this.db.object(siteInvitationsRef.child(<any>siteRef.payload.val()).child(emailHash)).valueChanges().pipe(take(1));
					}));
			},
			awaitingSignup: () => {
				return (<any>this.sites.getCurrentSiteKey())
					.valueChanges()
					.flatMap(siteRef => {
						return this.db.list(siteInvitationsRef.child(<any>siteRef.payload.val()));
					});
			},
			getInvite: (email: string, token: string, siteKey: string): Promise<any> => {
				return new Promise((resolve, reject) => {
					const emailHash = Md5.hashAsciiStr(email).toString();
					this.db.object(siteInvitationsRef.child(siteKey).child(emailHash))
						.snapshotChanges().pipe(
						take(1)).subscribe(invite => {
							const data = <any>invite.payload.val();
							if (invite.payload.exists() &&
								data.email === email && data.token === token) {
								resolve(invite);
							} else {
								reject('Error finding invite');
							}
						});
				});
			},
			sendInivite: (email: string, firstName: string = null, lastName: string = null, address: string = null) => {
				const emailHash = Md5.hashAsciiStr(email).toString();
				return this.sites.getCurrentSiteKey()
					.snapshotChanges().pipe(
					mergeMap(siteRef => {
						const token = Md5.hashAsciiStr(`${siteRef.payload.val()}-${(new Date()).getTime()}`);
						return this.db.object(siteInvitationsRef.child(<string>siteRef.payload.val()).child(emailHash)).set({
							createdBy: this.uid,
							createdOn: firebase.database.ServerValue.TIMESTAMP,
							email: email,
							firstName: firstName,
							lastName: lastName,
							address: address,
							name: `${firstName} ${lastName}`,
							completedSignup: false,
							token: token
						});
					}));
			},
			getCurrentSiteKey: () => {
				return this.db.object(currentSiteRef.child(this.uid));
			},
			getMembers: () => {
				return (<any>this.sites.getCurrentSiteKey()).snapshotChanges().flatMap(siteRef => {
					return this.db.list(siteUsersRef.child(siteRef.$value));
				});
			},
			createSite: (name: string) => {
				const ref = this.db.list(sitesRef).push({
					createdBy: this.uid,
					createdOn: firebase.database.ServerValue.TIMESTAMP,
					name: name
				});

				this.sites.addMember(this.uid, ref.key, "2");
				return ref;
			},
			setCurrentSite: (key: string) => {
				return this.db.object(currentSiteRef.child(this.uid)).set(key);
			},
			updateSite: (key: string, name: string) => {
				this.db.object(sitesRef.child(key)).update({
					name: name
				});
			}
		}
	}
}
