import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-maps',
  templateUrl: './tutorial-maps.component.html',
  styleUrls: ['./tutorial-maps.component.css']
})
export class TutorialMapsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}