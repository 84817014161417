import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { CheckOutService} from 'app/core/services/checkout.service';
import { AccountService } from "app/core/services/account.service";
import { ImageCroppedEvent } from 'ngx-image-cropper';
declare var $ :any;

@Component({
	selector: 'app-neighborhood-background-image-add',
	templateUrl: './neighborhood-background-image-add.component.html',
	styleUrls: ['./neighborhood-background-image-add.component.css']
})
export class NeighborhoodBackgroundImageAddComponent implements OnInit {
	@Input() backgroundImage;
	@Input() isParentCommunity;
	
	//image: string;
	file: any;
	data: any;		
	groupImage: any;
	//cropperSettings: CropperSettings;
	
	@Output() messageEvent = new EventEmitter<any>();  // sent to neighborhood create component	
	//@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

	imageChangedEvent: any = '';
	croppedImage: any = '';
	imageFileName: any = '';

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;	
		var image:any = new Image();					
		this.file = event.target.files[0];
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;								
			that.data.image = image.src;						
		};
		myReader.readAsDataURL(this.file);
	}
	
    imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}
	
    imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.data.image = image;	
	}
	
    cropperReady() {
		// cropper ready
	}
	
    loadImageFailed() {
		// show message
    }
	sendMessageFile() {
	
		this.messageEvent.emit(this.backgroundImage);
	}

	receiveMessageFile($event) {
	
		this.backgroundImage = $event;
	}
	
	constructor(private sitesService: SitesService,
		        private checkoutService: CheckOutService,) {

		this.croppedImage = this.data.image = this.backgroundImage;				
	}

	ngOnInit() {
		// this.sitesService.getCurrentNeighborhood()
		// 	.subscribe(site => {
		// 		if (site.backgroundImage) {
		// 			this.image = site.backgroundImage.downloadURL;
		// 		}
		// 	});		
		if (this.isParentCommunity == 'Community')
			this.backgroundImage = "/assets/images/community-mockup.jpg";			
		else
			this.backgroundImage = "/assets/images/group-mockup.jpg";

		this.file = [];		
	}		
}
