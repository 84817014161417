
import { Component, OnInit, Input, Output, EventEmitter, isDevMode, NgZone} from '@angular/core';
import { AccountService } from "app/core/services/account.service";
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { SitesService } from "app/core/services/sites.service";
import { AngularFireAuth } from "angularfire2/auth";

declare var google: any;

@Component({
	selector: 'app-update-profile',	
	templateUrl: './update-profile.component.html',
	styleUrls: ['./update-profile.component.css']
})

export class UpdateProfileComponent implements OnInit {

	saving = false;
	pvtAddress = false;
	uid: string;
	address = '';
	email = '';
	name = '';
	firstName = '';
	lastName = '';
	occupation = '';
	company = '';
	phoneNumbers = [];
	notes = '';
	education = '';
	photoURL = '';
	interests = [];

	newPhoneNumber = '';
	newPhoneNumberType = 'Mobile';
	newPhoneNumberReceiveSMS = true;
	newPhoneNumberReceiveVoice = true;

	groupObj = {count:0};
	groupSubscriptionObj = {status:''};

	communityBought: number = 0;
	communityBoughtObj = {count: 0};
	communitySubscriptionObj = {status:'none'};
	communityGroupObj = {count: 0};

	// search related 
	geocoder: any;
	element: HTMLInputElement;
	googleLoaded = false;
	lat;
	lng;
	addressValid = false;
	paymentGroupObj = {end: 0, invoice: ''};
	paymentCommunityObj = {end: 0, invoice: ''};
	invoiceGroupObj = {last4: 0};
	invoiceCommunityObj = {last4: 0};

	isSaving = false;
	siteObj = {key: ''};
	adminObj = {count: 0};
	adminCount = 0;
	currentUserIsAdministrator = false;

	memberKey = '';
	editMember = false;

	members: { key: string, selected: boolean, name: string }[] = [];

	constructor(private accountService: AccountService, 
		        private router: Router,
				private sitesService: SitesService,				
				private afAuth: AngularFireAuth,
				//private _mapsAPILoader: MapsAPILoader,
				private _ngZone: NgZone,
				private route: ActivatedRoute
				) {
		this.uid = accountService.currentUser.uid;
	}	

	ngOnInit() {	
		//this._mapsAPILoader.load().then(() => {
		//	this.googleLoaded = true;
		//	this.initMap();
		//});

		// check the number of administrators so that it can be checked in case the user decides to cancel their subscription
		let promisess = Promise.all(
			[
				this.accountService.getCurrentSite(this.siteObj)
			]
		);

		promisess.then(() => {	
			this.sitesService.isAdministrator(this.siteObj.key)
			.subscribe(isAdmin => this.currentUserIsAdministrator = isAdmin);

			this.accountService.currentSiteKey.subscribe(siteKey => {
				this.sitesService.getMemberKeys(siteKey)
					.subscribe(memberKeys => {
						memberKeys.forEach(key => {										
							this.sitesService.isMemberAdministrator(this.siteObj.key, key)
							.subscribe(isAdmin => {if (isAdmin) this.adminCount++});
						});
						
					});
			});
		}, error => {					
			alert(error)
		});
		
		this.memberKey = this.route.snapshot.queryParams['memberKey'] || null;  // applies to groups
		if (this.memberKey == null) {
			this.editMember = false;
			this.memberKey = this.afAuth.auth.currentUser.uid;
			this.accountService.getUserDetails()
			.valueChanges()
			.subscribe((details: any) => {	
				this.accountService.getPayment(details.email, this.paymentGroupObj, this.paymentCommunityObj).then(() => {	
					debugger;				
					this.accountService.getInvoice(this.paymentGroupObj.invoice, this.invoiceGroupObj);
					this.accountService.getInvoice(this.paymentCommunityObj.invoice, this.invoiceCommunityObj);
				}) 				
			});			
		
			this.accountService.getUserDetails()
			.valueChanges()
			.subscribe((details: any) => {								
				this.email = details.email;
				this.address = details.address;
				if (details.homeLocation) {
					 this.address = details.homeLocation.address
					 this.lat = details.homeLocation.latitude;
					 this.lng = details.homeLocation.longitude;
					 this.getAddress(this.address);
				 }
				if (details.profileImage)
					this.photoURL = details.profileImage.downloadURL;
					//$('updateProfileImageModal).hide()
				this.education = details.education ? details.education : '';
				this.name = details.name ? details.name : '';
				this.firstName = details.firstName ? details.firstName : '';
				this.lastName = details.lastName ? details.lastName : '';
				this.notes = details.notes ? details.notes : '';
				this.pvtAddress = details.pvtAddress ? details.pvtAddress : '';
				this.interests = details.interests ? details.interests.filter((value) => value) : [];
				this.phoneNumbers = details.phoneNumbers ? details.phoneNumbers.filter((value) => value) : [];									

				if (details.work) {
					this.occupation = details.work.occupation ? details.work.occupation : '';
					this.company = details.work.company ? details.work.company : '';
				}
			});
			// this.accountService.currentSiteKey.subscribe(siteKey => {
			// 	this.sitesService.getMemberKeys(siteKey)
			// 		.subscribe(memberKeys => {
						
			// 			memberKeys.forEach(key => {
			// 				this.members = [];
			// 				debugger;
			// 				var member = this.accountService.getMemberName(key);
			// 				this.members.push({
			// 					key: key,
			// 					selected: false,
			// 					name: ''
			// 				})
			// 			});
			// 		});
			// });
		}
		else {
			this.editMember = true;
			this.accountService.getMemberDetails(this.memberKey)
			.valueChanges()
			.subscribe((details: any) => {	
				this.accountService.getPayment(details.email, this.paymentGroupObj, this.paymentCommunityObj).then(() => {					
					this.accountService.getInvoice(this.paymentGroupObj.invoice, this.invoiceGroupObj);
					this.accountService.getInvoice(this.paymentCommunityObj.invoice, this.invoiceCommunityObj);
				}) 				
			});			
		
			this.accountService.getMemberDetails(this.memberKey)
			.valueChanges()
			.subscribe((details: any) => {								
				this.email = details.email;
				this.address = details.address;
				if (details.homeLocation) {
					 this.address = details.homeLocation.address
					 this.lat = details.homeLocation.latitude;
					 this.lng = details.homeLocation.longitude;
					 this.getAddress(this.address);
				 }
				if (details.profileImage)
					this.photoURL = details.profileImage.downloadURL;
					//$('updateProfileImageModal).hide()
				this.education = details.education ? details.education : '';
				this.name = details.name ? details.name : '';
				this.firstName = details.firstName ? details.firstName : '';
				this.lastName = details.lastName ? details.lastName : '';
				this.notes = details.notes ? details.notes : '';
				this.pvtAddress = details.pvtAddress ? details.pvtAddress : '';
				this.interests = details.interests ? details.interests.filter((value) => value) : [];
				this.phoneNumbers = details.phoneNumbers ? details.phoneNumbers.filter((value) => value) : [];								
				if (details.work) {
					this.occupation = details.work.occupation ? details.work.occupation : '';
					this.company = details.work.company ? details.work.company : '';
				}
			});
		}
		
			
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.memberKey, this.groupSubscriptionObj, this.groupObj),
				this.sitesService.getUserCommunityInfo2(this.memberKey, this.communitySubscriptionObj, this.communityGroupObj, this.communityBoughtObj)
			]
		);

		promises.then(() => {				
			this.initMap();									
														
		}, error => {					
			alert(error)
		});	
	}

	initMap() {		
		
		this.element = <HTMLInputElement>document.getElementById('pac-input');	
		var placesToFind = new google.maps.places.SearchBox(this.element);	 
        // Listen for the event fired when the user selects a prediction and retrieve
		// more details for that place.
		let that = this;
		//let addressaddress = this.address;
		//var myplace;
        placesToFind.addListener('places_changed', function() {		  	  			
          var places = placesToFind.getPlaces();

          if (places.length == 0) {
            return;
		  }		  
		  //myplace = thisthis.getFormattedAddress(places[0]);   
		  that.address = places[0].formatted_address; 
		  that.getAddress(that.address);		  
		});
                  		
	}


	getAddress(e) {
		//console.log(e)		
        this.geocoder = new google.maps.Geocoder;
        this.address = e;
        this.geocoder.geocode({ 'address': e }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                this._ngZone.run(() => {
				
					if (results[0].types.length > 0)
					{
						var i;
						for (i = 0; i < results[0].types.length; i++)
						{
							if (results[0].types[i] == 'street_address' ||
							    results[0].types[i] == 'premise')
							{
							
								this.addressValid = true;
								break;
							}
						}
					}
                    this.lat = results[0].geometry.location.lat();
					this.lng = results[0].geometry.location.lng();  
                });
            } else {				               
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
	}	

/*
	getFormattedAddress(place) {
		//@params: place - Google Autocomplete place object
		//@returns: location_obj - An address object in human readable format
		let location_obj = {};
		for (let i in place.address_components) {
		  let item = place.address_components[i];
		  
		  location_obj['formatted_address'] = place.formatted_address;
		  if(item['types'].indexOf("locality") > -1) {
			location_obj['locality'] = item['long_name']
		  } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
			location_obj['admin_area_l1'] = item['short_name']
		  } else if (item['types'].indexOf("street_number") > -1) {
			location_obj['street_number'] = item['short_name']
		  } else if (item['types'].indexOf("route") > -1) {
			location_obj['route'] = item['long_name']
		  } else if (item['types'].indexOf("country") > -1) {
			location_obj['country'] = item['long_name']
		  } else if (item['types'].indexOf("postal_code") > -1) {
			location_obj['postal_code'] = item['short_name']
		  }
		 
		}
		return location_obj;
	  }*/

	removePhoneNumber(phoneNumber) {
		this.phoneNumbers.splice(this.phoneNumbers.indexOf(phoneNumber), 1);
	}

	isPhoneValid(cell) {
		debugger;
		if (cell[0] == '(' &&
			(cell[1] >= '0' && cell[1] <= '9') &&
			(cell[2] >= '0' && cell[2] <= '9') &&
			(cell[3] >= '0' && cell[3] <= '9') &&
			cell[4] == ')' && 
			cell[5] == ' ' &&
			(cell[6] >= '0' && cell[6] <= '9') &&
			(cell[7] >= '0' && cell[7] <= '9') &&
			(cell[8] >= '0' && cell[8] <= '9') &&
			cell[9] == '-' &&
			(cell[10] >= '0' && cell[10] <= '9') &&
			(cell[11] >= '0' && cell[11] <= '9') &&
			(cell[12] >= '0' && cell[12] <= '9') &&
			(cell[13] >= '0' && cell[13] <= '9')																		
			)
			return true;
		else
			return false;
	}

	newPhoneNumberChanged() {
		var phoneValid = this.isPhoneValid(this.newPhoneNumber);
		
		if (phoneValid) {
			this.addPhoneNumber();
		}
	}

	addPhoneNumber() {
		this.phoneNumbers.push({
			number: this.newPhoneNumber,
			type: this.newPhoneNumberType,
			receiveSMS: this.newPhoneNumberReceiveSMS,
			receiveVoice: this.newPhoneNumberReceiveVoice
		});		

		this.newPhoneNumber = '';
		this.newPhoneNumberType = 'Mobile';
		this.newPhoneNumberReceiveSMS = true;
		this.newPhoneNumberReceiveVoice = true;
	}

	updateGroupSubscription(){
		if (this.groupSubscriptionObj.status == 'essential')  // go to essential, plus, or premium
			this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: this.groupSubscriptionObj.status }});
		else if (this.groupSubscriptionObj.status == 'plus')  // go straight to stripe for premium			
			this.router.navigate(['/neighborhood/create'], {queryParams: {communityType: 'Group', subscriptionType: 'premium'}});
		else  // maybe they will downgrade
			this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: this.groupSubscriptionObj.status }});
	}

	updateCommunitySubscription() {
		this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Community', subscriptionType: this.communitySubscriptionObj.status, upgrade: 1 }});
	}

	addressChange($event) {
		this.addressValid = false;
	}

	

	submit() {
		
		if (this.addressValid == false) {				
			alert('Your address is invalid. Please type in your address again and select from the results.');
			return;
		}

		var i;
		for (i = 0; i < this.phoneNumbers.length; i++) {			
			this.phoneNumbers[i].number = this.accountService.changeCellNumberFormat(this.phoneNumbers[i].number);
		}

		this.saving = true;	
		if (this.editMember) {
			this.accountService.updateMemberDetails(this.memberKey,
				this.firstName + ' ' + this.lastName, this.firstName, this.lastName, this.occupation, this.company, this.phoneNumbers, this.notes, this.education, this.pvtAddress, this.address, this.lat, this.lng, this.interests
			).then((data) => { 
				//console.log(data)
				this.saving = false; 
			this.router.navigate(['/edit-profile'])
			},
				error => { this.saving = false; });	
		}
		else {
			this.accountService.updateDetails(
				this.firstName + ' ' + this.lastName, this.firstName, this.lastName, this.occupation, this.company, this.phoneNumbers, this.notes, this.education, this.pvtAddress, this.address, this.lat, this.lng, this.interests
			).then((data) => { 
				//console.log(data)
				this.saving = false; 
			this.router.navigate(['/edit-profile'])
			},
				error => { this.saving = false; });	
		}
																														
	}

	cancel() {
		this.ngOnInit();
	}

	//test() {
		//var customerObj = {email: ''};
		//this.accountService.getEmail('cus_IX0y5nMkKX2X80', customerObj);
		//this.accountService.getEmail2('cus_IX0y5nMkKX2X80', customerObj);
		//this.sitesService.setSubscriptionToBasic('cus_IX0y5nMkKX2X80');
	//}

	async accessCustomerPortal() {		
		
		if (this.adminCount > 1 && 
		   ((this.groupObj.count < 2 && this.communityGroupObj.count < 1) ||
		   ((this.groupObj.count < 1 && this.communityGroupObj.count < 2) )))  // user can still belong to one group or community
			this.accountService.showPortal();						
		else if (this.groupObj.count > 1)  
			alert('You are still a member of ' + this.groupObj.count + ' groups. Please remove yourself from all groups except for one.');
		else if (this.communityGroupObj.count > 1)
			alert('You are still a member of ' + this.communityGroupObj.count + ' communities. Please remove yourself from all communities except for one.');
		else if (this.adminCount == 1 && this.currentUserIsAdministrator)
			alert('There is only one administrator for this group and that is you. Please assign admin status to another member before cancelling your subscription.');
		else
		   	alert('Unable to cancel your subscription through Wissle.  Contact support.');
	}


}
