import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'app-file-type-placeholder',
	templateUrl: './file-type-placeholder.component.html',
	styleUrls: ['./file-type-placeholder.component.css']
})
export class FileTypePlaceholderComponent implements OnInit, OnChanges {

	@Input() type: string;

	class = '';

	constructor() { }

	ngOnInit() {
		this.type = '';
	}

	ngOnChanges() {
		this.class = 'fa-file';
		
		if(this.type.indexOf('image') > -1) {
			this.class = 'fa-file-image-o'
		} else if(this.type.indexOf('application/excel') > -1 || 
			this.type.indexOf('application/vnd.ms-excel') > -1 || 
			this.type.indexOf('application/x-excel') > -1 || 
			this.type.indexOf('application/x-msexcel') > -1) {
			this.class = 'fa-file-excel-o'
		} else if(this.type.indexOf('application/pdf') > -1) {
			this.class = 'fa-file-pdf-o '
		} else if(this.type.indexOf('application/msword') > -1 ||
			this.type.indexOf('application/msword') > -1 ||
			this.type.indexOf('document') > -1 ){
			this.class = 'fa-file-word-o'
		}  else if(this.type.indexOf('application/x-gzip') > -1 ||
			this.type.indexOf('application/x-gzip') > -1 || 
			this.type.indexOf('multipart/x-gzip') > -1 ||
			this.type.indexOf('application/x-compressed') > -1 ||
			this.type.indexOf('application/x-zip-compressed') > -1 ||
			this.type.indexOf('application/zip') > -1 || 
			this.type.indexOf('multipart/x-zip') > -1) {
			this.class = 'fa-file-zip-o'
		} else if(this.type.indexOf('video') > -1) {
			this.class = 'fa-file-video-o'
		} else if(this.type.indexOf('powerpoint') > -1 ||
			this.type.indexOf('presentation') > -1) {
			this.class = 'fa-file-powerpoint-o '
		}
	}
}//
//presentation
//  fa-file-word-o 
//  fa-file-file fa-file-powerpoint-o 
