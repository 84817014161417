import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AccountService } from "app/core/services/account.service";

@Component({
	selector: 'app-update-password',
	templateUrl: './update-password.component.html',
	styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

	currentPassword = '';
	password = '';
	confirm = '';
	email = '';
	message = '';

	@Output() passwordUpdated = new EventEmitter<boolean>();

	constructor(public accountSerivce: AccountService) { }

	ngOnInit() {
	}

	update() {
		this.message = '';
		if(this.password) {
			if(this.password == this.confirm) {
				this.accountSerivce.updatePassword(this.email, this.currentPassword, this.password)
					.then(() => {
						this.message = '';
						this.passwordUpdated.emit(true);
					}, error => {
						this.message = error.message;
					});
			} else {	
				this.message = 'New password does not match confirm password';
			}
		} else {
			this.message = 'Please fillout new password';
		}
	}
}
