import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { CommentsService } from "app/comments/comments.service";
import { AccountService } from "app/core/services/account.service";

@Component({
	selector: 'app-comment-item',
	templateUrl: './comment-item.component.html',
	styleUrls: ['./comment-item.component.css']
})
export class CommentItemComponent implements OnInit, OnChanges {

	@Input() key: string;
	@Input() parentKey: string;

	comment: any;
	canDelete = false;
	editing = false;

	constructor(private commentsService: CommentsService,
				private accountService: AccountService) { }

	ngOnInit() {
		this.comment = this.commentsService.getComment(this.key).valueChanges();
		this.accountService.isNeighborhoodAdministrator
		.subscribe(isAdmin => {
			this.canDelete = isAdmin || this.comment.createdBy === this.accountService.currentUser.uid;
		});
	}

	ngOnChanges() {
				
	}

	delete() {
		if (confirm('Press OK to delete this comment'))
			this.commentsService.delete(this.parentKey, this.key);
	}
	edit(val) {
		this.editing = val;
	}
	cancel( ){
		this.edit(false);
	}
	update(comment) {
		this.commentsService.editComment(comment.text, this.key);
		this.edit(false);
	}
}
