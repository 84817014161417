
import {take} from 'rxjs/operators';
import { SitesService } from 'app/core/services/sites.service';
import { AddressComponent } from './../../shared/address/address.component';
import { GroupSelectorComponent } from './../../groups/group-selector/group-selector.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppEngineService, MessageTypes } from "app/app-engine.service";
import { DiscussionService } from "app/discussion/discussion.service";
import { PostsService } from "app/posts/posts.service";
import { UploaderComponent } from "app/files/uploader/uploader.component";
import { PostTypesSelectorComponent } from "app/posts/post-types/post-types-selector/post-types-selector.component";
import { AccountService } from "app/core/services/account.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-post-item-create',
	templateUrl: './post-item-create.component.html',
	styleUrls: ['./post-item-create.component.css']
})
export class PostItemCreateComponent implements OnInit {

	@ViewChild(UploaderComponent) uploaderComponent: UploaderComponent;

	@ViewChild(GroupSelectorComponent) groupSelector: GroupSelectorComponent;

	@ViewChild(PostTypesSelectorComponent) postTypesSelector: PostTypesSelectorComponent;

	@ViewChild(AddressComponent) addressComponent: AddressComponent;

	text = '';
	title = '';
	subTitle = '';
	tags = [];
	titleColor = '';
	alertMessage = '';
	selectedPostType: any;

	constructor(private router: Router,
		private activatedRoute: ActivatedRoute,
		private accountService: AccountService,
		private sitesService: SitesService,
		private postsService: PostsService) {

	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.postsService.getPostTypes()
				.snapshotChanges().pipe(
				take(1)).subscribe((postTypes: any) => {
					postTypes = postTypes.map(postType =>
						Object.assign({ key: postType.key }, postType.payload.val()));
					this.postTypesSelector.finishedLoading.subscribe(() => {
						//console.log(postTypes)
						this.postTypesSelector.selected(postTypes.find(postType => postType.key == params['key']));
					});
				});
		});
	}

	postTypeSelected(postType) {
		//console.log(postType)
		

		this.selectedPostType = postType;
		this.title = postType.displayName;
		this.subTitle = postType.subDisplayName;
		this.text = postType.text + ' ';
		this.titleColor = postType.color;

		this.accountService.currentSiteKey.subscribe(siteKey => {
			this.sitesService.getGroup(siteKey)
				.valueChanges()
				.subscribe((site: any) => {
						
				this.accountService.getUserDetails()
					.valueChanges()
					.subscribe((details: any) => {	
						this.text = this.text
									.replace('{member}', details.displayName)
									.replace('{site}', site.name);
			
					});	
				});
		});
		
	}

	submit(sendUrgently: boolean = false) {
		
		if (this.text.trim().length) {
			this.postsService.createPost(
				sendUrgently,
				this.text,
				this.title,
				//this.subTitle,
				this.selectedPostType,
				this.groupSelector.selectedGroupKey,
				this.addressComponent.location.longitude,
				this.addressComponent.location.latitude,
				this.addressComponent.location.address,
				this.tags,
				this.uploaderComponent.albumKey,
				this.titleColor);

			this.cancel();
		}
	}

	cancel() {
		this.text = '';
		this.tags = [];
		this.uploaderComponent.clear();
		this.router.navigate(['neighborhood', 'alerts']);
	}
}
