import { Directive, ElementRef, OnInit } from '@angular/core';
import { AccountService } from "app/core/services/account.service";
import { SitesService } from "app/core/services/sites.service";

@Directive({
	selector: '[currentNeighborhoodDisplayName]'
})
export class CurrentNeighborhoodDirective implements OnInit {

	constructor(private accountService: AccountService,
		private sitesService: SitesService,
		private elementRef: ElementRef) { }

	ngOnInit() {
		//console.log('d')
		this.accountService.currentSiteKey.subscribe(siteKey => {
			this.sitesService.getGroup(siteKey)
				.valueChanges()
				.subscribe((site: any) => {
					this.elementRef.nativeElement.innerHTML = site.name;
				});
		});
	}

}
