import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AccountService } from "app/core/services/account.service";

@Component({
	selector: 'app-update-email',
	templateUrl: './update-email.component.html',
	styleUrls: ['./update-email.component.css']
})
export class UpdateEmailComponent implements OnInit {

	password = '';
	newEmail = '';
	email = '';
	message = '';

	@Output() emailUpdated = new EventEmitter<boolean>();

	constructor(private accountService: AccountService) { }

	ngOnInit() {
	}

	update() {
		this.accountService.updateEmail(this.email, this.password, this.newEmail)
			.then(() => {
				this.emailUpdated.emit();
				this.message = '';
			},
			error => this.message = error.message);
	}
}
