import { Component, Input, OnInit, ViewChild, isDevMode } from '@angular/core';
import { AgmMap } from "@agm/core";
import { SitesService } from "app/core/services/sites.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SubscriptionComponent} from 'app/neighborhood/subscription/subscription.component';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { CheckoutComponent } from 'app/neighborhood/checkout/checkout.component';
import { Subscription } from 'rxjs';
import { CheckOutService} from 'app/core/services/checkout.service';
import { MembersComponent } from '../members/members.component';
import { AngularFireAuth } from "angularfire2/auth";
import { AccountService } from "app/core/services/account.service";
import { NotificationsService } from './../../notifications/notifications.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
declare var $ :any;

declare var google;

@Component({
	selector: 'app-neighborhood-create',
	templateUrl: './neighborhood-create.component.html',
	styleUrls: ['./neighborhood-create.component.css']	
})
export class NeighborhoodCreateComponent implements OnInit {

	@ViewChild (SubscriptionComponent) subscription: SubscriptionComponent;
	@ViewChild(AgmMap) map: AgmMap;
	@ViewChild('updatePlan') modalSubscription: ModalComponent;
	@ViewChild(CheckoutComponent) checkout: CheckoutComponent;
	@ViewChild(MembersComponent) members: MembersComponent;

	name = '';
	description = '';
	typeSubscription = '';  // from hoa or about page
	typeCommunity = '';	
	newuser = 0;
	typeGroup = '';
	
	saving = false;
	
	groupObj = {count:0};
	subscriptionObj = {status:''};

	communityBought: number = 0;
	communityBoughtObj = {count: 0};
	communityObj = {status:''};
	communityGroupObj = {count: 0};
	communityUpgrade = false;

	step: number = 0;  // tracks which step the user is currently on
	
	parentCommunity = false;
	parentGroup = false;
	parentSubscriptionArr = [false, false, false];	// essential, plus, or premium
	parentSubscriptionFullArr = [false, false, false];  // flags it if there is no more room in subscription

	tabs = [ {active: false}, {active: false}, {active: false}];	

	session_id: string = '';
	message:string;

	complete = false;
	isLoading = true;

	website = 'https://dev.wissle.com';

	isAuthenticated = false;
	hasNewNotifications: any;
	unreadNotificationCount: any;
	notifications: any;
	inviteAccept = false;
	inviteDecline = false;

	//messageFile:string;
		
	backgroundImage: any;
		
	file: any;	
	data: any;

	imageChangedEvent: any = '';
	croppedImage: any = '';
	imageFileName: any = '';

    fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;	
		var image:any = new Image();					
		this.file = event.target.files[0];
		
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;								
			that.data.image = image.src;						
		};
		myReader.readAsDataURL(this.file);
	}
	
    imageCropped(event: ImageCroppedEvent) {
		this.data.image = this.croppedImage = event.base64;
	}
	
    imageLoaded(image: HTMLImageElement) {
		// show cropper	
		this.data.image = image;	
	}
	
    cropperReady() {
		// cropper ready
	}
	
    loadImageFailed() {
		// show message
    }

	constructor(private router: Router, 
				private sitesService: SitesService, 
				private checkoutService: CheckOutService,
				private afAuth: AngularFireAuth,
				private route: ActivatedRoute,
				private accountService: AccountService,
				private notificationsService: NotificationsService,		
				private titleService: Title) {
					titleService.setTitle('Wissle Create'); 	
					
		this.data = {};				
	}	

	ngOnInit() {
						
		
		this.accountService.getUserDetails();
		
		if (isDevMode())	
			//this.website = 'http://localhost:4200/';	
			this.website = 'https://dev.wissle.com/';

		this.accountService.isAuthenticated.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
			
			if (isAuthenticated) {
				this.hasNewNotifications = this.notificationsService.hasNewNotifications().valueChanges();

				this.notifications = this.notificationsService.getNotifications()
					.snapshotChanges()
					.pipe(map(snapshots => snapshots.map(snapshot => Object.assign({ key: snapshot.key }, snapshot.payload.val()))))
					
				/*	
					.forEach((item) => {
						//console.log("item", item);
						item.forEach(function(rec, index, object) {
							if (rec['action'] == '/neighborhood/members') {
								//object.splice(index, 1);							  
							}
							});					 						
							//return item;
					});
					*/

				this.notificationsService.getNotifications()
					.valueChanges()
					.subscribe((notifications: any) => {
						this.unreadNotificationCount = notifications.map(notification => notification.action)
						.filter(isRead => !isRead).length;
					});
			}
		});
				
		this.typeSubscription = this.route.snapshot.queryParams['subscriptionType'] || null;  // applies to groups
		this.typeCommunity = this.route.snapshot.queryParams['communityType'] || null;
		this.newuser = this.route.snapshot.queryParams['newuser'] || 0;
		this.communityUpgrade = this.route.snapshot.queryParams['upgrade'] || 0;

		this.session_id = this.checkoutService.getSessionID();	
		if (this.session_id != null){
			
			this.sitesService.getUserStatus(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj);
			// clear the session id from local storage
			this.checkoutService.clearSessionID();

			// update user's subscription level
			this.subscriptionObj.status = this.checkoutService.getSubscription();
								
			if (this.subscriptionObj.status == 'plus' ||
				this.subscriptionObj.status == 'premium')
			{
				this.typeCommunity = 'Group';
				// need to get community information too
				this.sitesService.getUserCommunityInfo(this.afAuth.auth.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj);											
			}				
			else
			{
				this.typeCommunity = 'Community';
				this.communityObj.status = this.subscriptionObj.status;
				this.communityBoughtObj.count = this.communityBought = this.checkoutService.getSubscriptionsBought() + 1;
				this.communityGroupObj.count = this.communityBoughtObj.count - 1;
			}
			this.sitesService.updateUserStatusSubscription(this.afAuth.auth.currentUser.uid, this.typeCommunity, this.subscriptionObj.status, this.communityBought);				
				
			// jump to group/community creation			
			this.tabs[0].active = false;
			this.tabs[1].active = false;
			this.tabs[2].active = true;

			this.step = 2;
			this.isLoading = false;	
		}
		else if (this.typeCommunity == 'Group') { // coming from home or about page
			this.isLoading = true;
			// initialize all tabs to false so that the group/community doesn't flicker before the subscription tab
			this.tabs[0].active = false;
			this.tabs[1].active = false;
			this.tabs[2].active = false;

			let promises = Promise.all(
				[
					this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj),
					this.sitesService.getUserCommunityInfo2(this.afAuth.auth.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj)
				]
			);

			promises.then(() => {	
			
				var canSkipSubscription = false;	
				var upgrade = false;			

				if (this.typeSubscription == 'essential')	
				{
					// jump to group/community creation	
					if (this.subscriptionObj.status == 'essential' && this.groupObj.count < 1)
						canSkipSubscription = true;
					else if (this.subscriptionObj.status == 'essential' && this.groupObj.count == 1)
						upgrade = true;
					else if (this.subscriptionObj == null && this.groupObj == null)  // must be new user
					{
						this.subscriptionObj.status = 'essential';
						this.groupObj.count = 0;
						canSkipSubscription = true;
					}	
					this.checkoutService.setGroupCount(this.groupObj.count);		
				}																	
				else if (this.typeSubscription == 'plus')  // they h
				{
					this.checkoutService.setGroupCount(this.groupObj.count);		
					if (this.subscriptionObj.status != 'plus')
						this.subscribePlus();
					else if (this.subscriptionObj.status == 'plus' && this.groupObj.count < 2)					
						canSkipSubscription = true;	
					else if (this.subscriptionObj.status == 'plus' && this.groupObj.count == 2)
						upgrade = true;														
				}					
				else if (this.typeSubscription == 'premium')
				{
					this.checkoutService.setGroupCount(this.groupObj.count);		
					if (this.subscriptionObj.status != 'premium')
						this.subscribePremium();
					else if (this.subscriptionObj.status == 'premium' && this.groupObj.count < 6)
						canSkipSubscription = true;						
				}					
				else  // do nothing
				{
					
				}

				if (canSkipSubscription)
				{					
					this.tabs[0].active = false;
					this.tabs[1].active = false;
					this.tabs[2].active = true;

					this.step = 2;
					this.isLoading = false;
				}
				else if (upgrade)  // try to upgrade
				{
					if (this.checkCommunitySelection())
					{												
						if (this.checkSubscription())  // they still have space in their subscription
						{
							this.tabs[0].active = false;
							this.tabs[1].active = false;
							this.tabs[2].active = true;
							this.step = 2;							
						}	
						else  // show subscription
						{						
							this.tabs[0].active = false;
							this.tabs[1].active = true;
							this.tabs[2].active = false;			
							this.step = 1;			
						}																											
					}
					else{ // show group community selection						
						this.tabs[0].active = true;
						this.tabs[1].active = false;
						this.tabs[2].active = false;												
					}		
					this.isLoading = false;				
				}
				else {	// show group/community selection				
					this.tabs[0].active = true;
					this.tabs[1].active = false;
					this.tabs[2].active = false;						
					this.isLoading = false;	
				}								
				

			}, error => {					
				alert(error)
			});	
		}
		else if (this.typeCommunity == 'small' ||
		         this.typeCommunity == 'medium' ||
				 this.typeCommunity == 'large' ||
				 this.typeCommunity == 'Community')  // coming from hoa/market so go directly to stripe
		{	
			this.isLoading = true;	
			// initialize all tabs to false so that the group/community doesn't flicker before the subscription tab
			this.tabs[0].active = false;
			this.tabs[1].active = false;
			this.tabs[2].active = false;

			let promises = Promise.all(
				[
					this.sitesService.getUserStatus2(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj),
					this.sitesService.getUserCommunityInfo2(this.afAuth.auth.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj)
				]
			);

			promises.then(() => {	
				if (this.newuser)
				{						
					this.communityObj.status = this.typeCommunity;
					this.communityBoughtObj.count = 0;
					this.communityGroupObj.count = 0;
				}

				if (this.communityUpgrade){		
					if (this.checkCommunitySelection()){												
						if (this.checkSubscription()) {  // they still have space in their subscription						
							this.tabs[0].active = false;
							this.tabs[1].active = false;
							this.tabs[2].active = true;
							this.step = 2;
						}	
						else {  // show subscription												
							this.tabs[0].active = false;
							this.tabs[1].active = true;
							this.tabs[2].active = false;			
							this.step = 1;				
						}						
					}
					else {
						this.tabs[0].active = true;
						this.tabs[1].active = false;
						this.tabs[2].active = false;
					}

					this.isLoading = false;													
				}
				else  // coming from hoa/market so go directly to stripe
				{
					if (this.typeCommunity == 'small' || (this.typeCommunity == 'Community' && this.typeSubscription == 'small'))																		
						this.subscribeSmall();
					else if (this.typeCommunity == 'medium'  || (this.typeCommunity == 'Community' && this.typeSubscription == 'medium'))
						this.subscribeMedium();
					else if (this.typeCommunity == 'large' || (this.typeCommunity == 'Community' && this.typeSubscription == 'large'))
						this.subscribeLarge();
					else {  // show group/community selection						
						this.tabs[0].active = true;
						this.tabs[1].active = false;
						this.tabs[2].active = false;
						this.isLoading = false;
					}												
				}				
									
			}, error => {					
				alert(error)
			});																																																						
		}
		else
		{
			this.sitesService.getUserStatus(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj);
			this.sitesService.getUserCommunityInfo(this.afAuth.auth.currentUser.uid, this.communityObj, this.communityGroupObj, this.communityBoughtObj);			
			
			this.tabs[0].active = true;
			this.tabs[1].active = false;
			this.tabs[2].active = false;
			this.isLoading = false;				
		}
		
		if (this.typeCommunity == 'Group' || this.typeCommunity == null)
			this.croppedImage = this.backgroundImage = "/assets/images/group-mockup.jpg";
		else
			this.croppedImage = this.backgroundImage = "/assets/images/community-mockup.jpg";
			
		this.accountService.profileCompleted
            .subscribe(isComplete => {
                //console.log(isComplete)
                if (isComplete) {
                    this.complete = true;
                } else {
                    this.complete = false;
                }
            });
		
	}	

	join(groupKey, createdBy) {	
		this.inviteAccept = true;
		var groupObj = {count:0};
		var subscriptionObj = {status:''};
		var canJoin = false;
				
		let promises = Promise.all(
			[
				this.sitesService.getUserStatus2(this.accountService.currentUser.uid, subscriptionObj, groupObj)
			]
		);

		promises.then(() => {	
/* 			if (subscriptionObj.status == 'essential' && groupObj.count < 1)
				canJoin = true;
			else if (subscriptionObj.status == 'plus' && groupObj.count < 2)
				canJoin = true;
			else if (subscriptionObj.status == 'premium' && groupObj.count < 6)
				canJoin = true;

			if (canJoin)
			{ */
				this.sitesService.addMember(this.accountService.currentUser.uid, groupKey, false);	
				groupObj.count++;
				this.sitesService.updateUserGroupStatus(this.accountService.currentUser.uid, groupObj);
				this.notificationsService.removeNotification(groupKey, createdBy);
				this.accountService.setCurrentSite(groupKey);
				this.router.navigate(['/neighborhood', 'alerts']);	
/* 			}
			else
			{
				alert('Your current subscription plan is full. If you wish to add one or more Wissle groups, please Premium for $1 or $2 per month.');
				
				this.checkoutService.setJoinGroupKey(groupKey);	
				this.checkoutService.setJoinGroupCreatedBy(createdBy);
				this.router.navigate(['/neighborhood/create'], { queryParams: { communityType: 'Group', subscriptionType: subscriptionObj.status }});
			} */
				
			
		}, error => {					
			alert(error)
		});										
	}

	decline(groupKey, createdBy) {			
		this.inviteDecline = true;
		this.notificationsService.removeNotification(groupKey, createdBy);
	}

	subscribePlus() {
		this.checkoutService.setSubscription('plus');	                                                                  
        this.accountService.subscribeTo('price_1I3wY3DZWXSMJjj8rti9dRRL', this.website); 					
	}

	subscribePremium() {		
		this.checkoutService.setSubscription('premium');	                                                                  
        this.accountService.subscribeTo('price_1I3wagDZWXSMJjj8QeDat0dH', this.website);
	}
	
	subscribeSmall() {		
		this.checkoutService.setSubscription('small');
		if (this.communityBoughtObj == null)
			this.checkoutService.setSubscriptionsBought(0);		
		else
			this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);

		this.accountService.subscribeTo('price_1I49G6DZWXSMJjj8IEItYljo', this.website);
	}

	subscribeMedium() {			
		this.checkoutService.setSubscription('medium');	
		if (this.communityBoughtObj == null)
			this.checkoutService.setSubscriptionsBought(0);		
		else
			this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		

		this.accountService.subscribeTo('price_1I49GgDZWXSMJjj895FB2TEm', this.website);
	}

	subscribeLarge() {			
		this.checkoutService.setSubscription('large');		
		if (this.communityBoughtObj == null)
			this.checkoutService.setSubscriptionsBought(0);		
		else
			this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);	

		this.accountService.subscribeTo('price_1I49H8DZWXSMJjj806IV5g46', this.website);
	}
	
	receiveMessage($event) {
		this.message = $event
		//console.log('child message:', this.message);
		this.tabs[0].active = false;
		this.tabs[1].active = false;
		this.tabs[2].active = true;
		this.step = 2;
	}	

	//receiveMessageFile($event) {		
	//	this.messageFile = $event
	//}
	
	public showSubscriptionPlus() { 

		this.modalSubscription.show(); 		
		return new Promise((resolve)=>{ 
			document.getElementById('checkout-button-price_1He1sRDZWXSMJjj8ptVskCaA').onclick = () => { 			
				resolve(true); 				
				this.modalSubscription.hide(); 								
			} 
		}) 
	}
	
	public showSubscriptionPremium() { 
			;
		//this.checkoutService.setInfo(this.name, this.description, 'premium', this.groupObj.count);
		this.modalSubscription.show(); 
		return new Promise((resolve)=>{ 
			document.getElementById('checkout-button-plan_HL5qPZX7m8TCRW').onclick = () => { 			
				resolve(true); 				
				this.modalSubscription.hide(); 								
			} 
		}) 
	}

	checkCommunitySelection()
	{	
		// consider the user always subscribed but not necessarily at full subscription	
		this.parentSubscriptionArr[0] = true;
		this.parentSubscriptionArr[1] = true;
		this.parentSubscriptionArr[2] = true;

		this.parentSubscriptionFullArr[0] = false;
		this.parentSubscriptionFullArr[1] = false;
		this.parentSubscriptionFullArr[2] = false;

		if (this.subscriptionObj.status == null)
		{
			this.subscriptionObj.status = 'essential';
			//console.log('NEW USER');
		}						

		if (this.typeCommunity == 'Group')
		{			
			if (this.subscriptionObj.status == 'essential')
			{					
				if (this.groupObj.count < 1)  // they can still add a group
					this.parentSubscriptionFullArr[0] = false;  // current
				else
					this.parentSubscriptionFullArr[0] = true;  // no more room
			}			
			else if (this.subscriptionObj.status == 'plus')
			{			
				this.parentSubscriptionFullArr[0] = true;  // essential would be full if they're in plus

				if (this.groupObj.count < 2)  // they can still add a group
					this.parentSubscriptionFullArr[1] = false;  // current
				else
					this.parentSubscriptionFullArr[1] = true;  // no more room
			}			
			else // premium
			{
				this.parentSubscriptionFullArr[0] = true;  // essential would be full if they're in premium
				this.parentSubscriptionFullArr[1] = true;  // plus would be full if they're in premium

				if (this.groupObj.count < 6) // they can still add a group
					this.parentSubscriptionFullArr[2] = false;  // current
				else
					this.parentSubscriptionFullArr[2] = true;  // no more room
			}

			this.parentGroup = true;
			this.parentCommunity = false;
			
			return true;
		}			
		else if (this.typeCommunity == 'Community')  // small, medium, or large have to pay, regardless
		{
			this.parentGroup = false;
			this.parentCommunity = true;				
			this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);	
						
			return true;
		}
		else if (this.inviteDecline || this.inviteAccept)  // reset
		{
			this.inviteAccept = false;
			this.inviteDecline = false;
			return false;
		}
		else
		{			
			alert('Please enter a member option!');
			return false;
		}	
	}

	checkStripeCheckout()
	{
		if (this.session_id != null)  // user paid for subscription
			return true;
		else
			return false;
	}

	checkCommunityFields()
	{
		if (this.name.trim() == '')
		{
			alert('Please enter a name for your group or community!');
			return false;
		}
		else
		{
			return true;
		}
		
	}

	checkSubscription()
	{
		// the different tiers for groups:
		// essential <= 0 (0 and 1)
		// plus <= 2 (2)
		// premium > 2 (3, 4, 5, 6)
		// typecommunity is either group or community
		// subscription is either essential, plus, or premium	
		;
		if (this.typeCommunity == 'Group')  // check subscription
		{
			if (this.subscriptionObj.status == 'essential')
			{				
				return false;  // always show them the screen
			}
			else if (this.subscriptionObj.status == 'plus')
			{
				if (this.groupObj.count < 2)
					return true;  // bypass subscription screen
				else
					return false;  // show the subscription screen
			}
			else if (this.subscriptionObj.status == 'premium')
			{
				if (this.groupObj.count < 6)
					return true;  // bypass subscription screen
				else
					return false;  // show the subscription screen	
			}			
		}
		else  // community
		{		
			if (this.communityObj.status == 'essential')
			{
				return false;  // need to pay first
			}	
			else if (this.communityObj.status == 'small' ||
			         this.communityObj.status == 'medium' ||
			         this.communityObj.status == 'large')
			{
				if (this.communityGroupObj.count < this.communityBoughtObj.count)
					return true;
				else
					return false;						
			}
			else 
			{
				alert('Unknown community selection!');
				return false;
			}
			
		}
	}

	processStep()
	{		
		switch(this.step)
		{
			case 0:  // no community selection
				if (this.checkCommunitySelection())
				{												
					if (this.checkSubscription())  // they still have space in their subscription
					{
						this.tabs[0].active = false;
						this.tabs[1].active = false;
						this.tabs[2].active = true;
						this.step = 2;
					}	
					else
					{						
						this.tabs[0].active = false;
						this.tabs[1].active = true;
						this.tabs[2].active = false;			
						this.step = 1;				
					}																						
				}
					
				break;
			case 1: // no stripe
				
				// check their current subscription
				
				if (this.checkStripeCheckout())  // if stripe payment is done
				{
					this.tabs[0].active = false;
					this.tabs[1].active = false;
					this.tabs[2].active = true;
					this.step = 2;				
				}				
								
				break;
			case 2:
				// if community description is chosen
				;
				if (this.checkCommunityFields())
				{	
					this.checkoutService.setGroupCount(this.groupObj.count);		
					this.step = 3;
				}					
				break;
			
		}
		
		return this.step;			
	}	
		

	submit() {
		;
		if (this.processStep() < 3)
			return;
		else  // if (this.step == 3)  // ready to check out		
		{											
			this.saving = true;								
			var groupType = this.subscriptionObj.status;
			if (this.typeCommunity == 'Community')
				groupType = this.communityObj.status;

			let ref = this.sitesService.create(this.name, groupType);			
			this.accountService.setCurrentSite(ref.key);
			ref.then((ref) => {	
				var key = ref.key;		
				this.groupObj.count = this.checkoutService.getGroupCount();	// grab the group count from local storage because for some reason the value gets doubled resulting in erroneous counts in group_status
				if (this.typeCommunity == 'Group')
					this.groupObj.count++;
				else if (this.typeCommunity == 'Community')
					this.communityGroupObj.count++;

				this.accountService.addJoinCode(ref.key);

				let promises = Promise.all(
					[																													
						this.sitesService.updateDetails(ref.key, this.name, this.description, this.typeGroup),	
						this.sitesService.updateUserGroupStatus(this.afAuth.auth.currentUser.uid, this.groupObj),
						this.sitesService.updateUserCommunityStatus(this.afAuth.auth.currentUser.uid, this.communityGroupObj),
						this.sitesService.updateBackgroundImage(ref.key, this.file, this.data.image, false)											
					]
				);
																
				promises.then(() => {															
					this.saving = true;	
					
					if (this.complete)		
					    this.router.navigate(['neighborhood/members'], {queryParams: {welcome: 1}});		
					else
						this.router.navigate(['complete-profile'], { queryParams: { prevNeighborhoodUrl: '/neighborhood/members'}});
				}, error => {
					this.saving = false;
					alert(error)
				});											

			}, error => {
				alert(error.message)
				this.saving = false;
			});
									
		}															
	}
}
