
import {take} from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { GeocodeService, Location } from "app/core/services/geocode.service";
import { AgmMap } from "@agm/core";
import { AccountService } from "app/core/services/account.service";
declare var google: any;

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

	@ViewChild('map') map: AgmMap;

	@Input() isReadonly = false;
	@Input() location: Location;

	public isCleared = false;
	public zoom = 18;
	public address = '';
	public latitude = 0;
	public longitude = 0;
	public message = '';
	public isDisabled = false;
	public isOpen = false;

	private geocoder: any;
	private closeMap = false;
	element:HTMLButtonElement;

	constructor(private geocode: GeocodeService, private accountService: AccountService) { }

	ngOnDestroy() {
	}

	ngOnInit() {
		/*this.subscriables.push(this.mapControl.editableMarkerDragend.subscribe(latLng => {
			this.latitude = latLng.lat;
			this.longitude = latLng.lng;
			this.geocode.getAddressFromPosition(latLng.lat, latLng.lng)
				.then(position => {
					this.address = position.formatted_address;
				}, error => {
				})
        }));*/
		this.accountService.user.subscribe(user => {
			if (user) {
				this.accountService.getHomeAddress()
					.snapshotChanges().pipe(take(1))
					.subscribe(location => {

						if (location.payload.exists()) {
							this.latitude = (<any>location.payload.val()).latitude;
							this.longitude = (<any>location.payload.val()).longitude;
							this.address = (<any>location.payload.val()).address;
							this.location = (<any>location.payload.val());
							this.message = '';
						} else {
							this.message = 'Finding your address...';

							this.isDisabled = true;
							if (navigator.geolocation) {
								navigator.geolocation.getCurrentPosition(position => {
									this.updateAddressByLatLng(position.coords.latitude, position.coords.longitude)
										.then(() => {
											this.addLocation()
											this.isDisabled = false;
											this.message = '';

											if (prompt('Would your like to set the following address as your home location?', this.address)) {
												this.accountService.setHomeAddress(
													this.address,
													this.latitude,
													this.longitude
												);
											}
										}, () => {
											this.message = 'Could not find your location';
										});
								}, () => {
									this.message = 'Could not find your location';
								});
							} else {
								this.message = 'Could not find current locations';
								this.location = null;
							}
						}
					});
			}
		});
	}

	ngAfterViewInit() {
		this.closeMap = false;
	}

	ngOnChanges() {
		if (this.location) {
			this.address = this.location.address;
			this.latitude = this.location.latitude;
			this.longitude = this.location.longitude;
		} else {

		}
	}

	update() {
		//;
		this.location.address = this.address;
		this.location.longitude = this.longitude;
		this.location.latitude = this.latitude;
		this.closeMap = true;
		//this.element = <HTMLInputElement>document.getElementById('alertAddressID');	
		//this.element.focus();			
	}

	openMap() {
		//;
		this.closeMap = false;
		//this.element = <HTMLInputElement>document.getElementById('alertAddressID');	
		//this.element.focus();
	}

	clear() {
		this.isCleared = true;
		this.location = null;
		this.closeMap = false;
	}

	addLocation() {
		this.isCleared = false
		this.location = {
			address: this.address,
			longitude: this.longitude,
			latitude: this.longitude
		}
	}

	getCurrentLocation() {
		this.geocode.getCurrentLocation()
			.then(location => {
				this.location = location;
				this.address = this.location.address;
				this.latitude = this.location.latitude;
				this.longitude = this.location.longitude;
			})
	}

	mapToggeled(isOpen) {
		this.isOpen = isOpen;
	}

	markerDragEnd(event) {
		this.updateAddressByLatLng(event.coords.lat, event.coords.lng);
	}

	updateAddressByLatLng(lat: number, lng: number) {
		return new Promise((resolve, reject) => {
			//TODO
			this.geocoder = new google.maps.Geocoder;
			this.latitude = lat;
			this.longitude = lng;

			return this.geocoder.geocode({ 'location': { lat: this.latitude, lng: this.longitude } }, (results, status) => {
				if (status === 'OK') {
					if (results[0]) {
						this.address = results[0].formatted_address;
					} else {
						alert('No results found');
					}
					resolve();
				} else {
					alert('Geocoder failed due to: ' + status);
					reject();
				}
			});
		});
	}
}