import { Component, OnInit } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { AccountService } from "app/core/services/account.service";
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-neighborhood-quick-view',
	templateUrl: './neighborhood-quick-view.component.html',
	styleUrls: ['./neighborhood-quick-view.component.css']
}) 
export class NeighborhoodQuickViewComponent implements OnInit {

	neighborhoodsDetails = [];
	site: any;
	siteKey: any;
	currentSite: any;
	neighborhoods: any;
//
	backgroundImageGroup;
	backgroundImageCommunity;
	siteObj = {key: 'default key'};

	constructor(private sitesService: SitesService,
				private accountService: AccountService) { 

		this.neighborhoods = this.sitesService.getNeighorhoods()
		.snapshotChanges().pipe(map(snapshots => snapshots.map(snapshot => {
		return { $key: snapshot.key }
		})));
		
		//console.log(this.neighborhoods)
		this.neighborhoods.subscribe(a => {
			//console.log('s', JSON.stringify(a))

			
					this.accountService.getCurrentSite(this.siteObj);
					this.getNeighborhoodsDetails(a);
										

		})
	}

	getNeighborhoodsDetails(a){
		var firstKey = true;
		a.forEach(element => {
			
			if (element.$key) {
			
				this.sitesService.getGroup(element.$key)
					.valueChanges()
					.subscribe((site: any) => {
						
						//console.log("name", site.name)
						if(site && site.name){
							
							var noImage = false;
							if (site.backgroundImage.downloadURL == 'assets/images/community-mockup.jpg')					
								noImage = true;

							var isGroup = false;
							if (site.groupType == 'essential' || site.groupType == 'plus' || site.groupType == 'premium')
								isGroup = true;
							this.neighborhoodsDetails.push({"address": site.address, "noImage":noImage, "isGroup":isGroup, "backgroundImage":site.backgroundImage.downloadURL, "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
							//this.neighborhoodsDetails.push({"address": site.address,  "createdBy": site.createdBy, "description": site.description, "groupType": site.groupType, "memberCount": site.memberCount, "name": site.name, "siteType": site.siteType, "siteKey":element.$key});
							//console.log(this.neighborhoodsDetails)
							this.accountService.currentSiteKey.subscribe(siteKey => {
								//console.log("siteKey", siteKey)
								this.siteKey = siteKey;
						});
						//alert("aaa")
						const filteredArr = this.neighborhoodsDetails.reduce((acc, current) => {
							const x = acc.find(item => item.siteKey === current.siteKey);
							if (!x) {
								return acc.concat([current]);
							} else {
								return acc;
							}
							}, []);
							this.neighborhoodsDetails = filteredArr;
							//console.log("this.neighborhoodsDetails", this.neighborhoodsDetails)
							//this.neighborhoodsevents.emit(filteredArr);
							
							if (this.siteObj.key == element.$key)
								this.selectNeighborhood(element.$key);
																
							//console.log(this.currentSite)
							
						}
						
					});
			} else {
				
				this.accountService.currentSiteKey.subscribe(siteKey => {
					
					this.sitesService.getGroup(siteKey)
						.valueChanges()
						.subscribe((site: any) => {
							
							//console.log("name", site.name)
							if(site && site.name){
								this.neighborhoodsDetails.push({"site": site, "siteKey":element.$key});
								//this.elementRef.nativeElement.innerHTML = site.name;
							}
							
						});
				});
			}
		});

		//console.log(this.neighborhoodsDetails)
		
	}
			

	ngOnInit() {							
		this.sitesService.currentMessageKey.subscribe(message => {this.selectNeighborhood(message);});
		this.backgroundImageGroup = "/assets/images/group-mockup.jpg";
		this.backgroundImageCommunity = "/assets/images/community-mockup.jpg";
	}	

	selectNeighborhood(key) {
		if (key != 'default key')
		{	
			let promises = Promise.all(
				[
					this.accountService.getCurrentSite(this.siteObj)
				]
			);
	
			promises.then(() => {														
				if (key == this.siteObj.key && 
					this.neighborhoodsDetails.length > 0) {
					
					this.currentSite = this.neighborhoodsDetails.find(ele=>ele.siteKey == key);
					if (this.currentSite != null)
					{
						if (this.currentSite.noImage)
						{
							if (this.currentSite.isGroup)
								this.currentSite.backgroundImage = this.backgroundImageGroup;
							else
								this.currentSite.backgroundImage = this.backgroundImageCommunity;
						}
						//console.log(JSON.stringify(this.currentSite))
					}
				}
								
										
			}, error => {					
				alert(error)
			});				
															
		}				
	}

	// displayData(event){
	// 	var filteredArr = event.reduce((acc, current) => {
	// 		const x = acc.find(item => item.siteKey === current.siteKey);
	// 		if (!x) {
	// 		  return acc.concat([current]);
	// 		} else {
	// 		  return acc;
	// 		}
	// 	  }, []);
	// 	  this.neighborhoods = [];
	// 	this.neighborhoods = filteredArr;
	// }

	// currentSiteDetail(event){
	// 	this.currentSite = {};
	// 	this.currentSite = event;
	// 	//console.log("event", event)
	// }
	

}
