import { AccountService } from './../../core/services/account.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";

@Component({
	selector: 'app-neighborhood-selector',
	templateUrl: './neighborhood-selector.component.html',
	styleUrls: ['./neighborhood-selector.component.css']
})
export class NeighborhoodSelectorComponent implements OnInit {

	@ViewChild(ModalComponent) modal: ModalComponent;
	@Input()neighborhoodsDetails: any;
	@Input() currentSite: any;
	neighborhoods: any;
	siteKey : any;	

	constructor(private sitesService: SitesService, public accountService: AccountService) { }

	ngOnInit() {
	}	

	selectNeighborhood(key) {		
		this.sitesService.changeNeighborhood(key);		
		this.modal.hide();
	}

}
