import { Component, OnInit, Input } from '@angular/core';
import { QuickListService } from "app/core/services/quick-list.service";

@Component({
	selector: 'app-quick-list-view',
	templateUrl: './quick-list-view.component.html',
	styleUrls: ['./quick-list-view.component.css']
})
export class QuickListViewComponent implements OnInit {

	@Input() title: string;
	@Input() parentKey: string;
	@Input() type: string;
	@Input() readonly: boolean = true;

	items: any;

	constructor(private quickListService: QuickListService) { }

	ngOnInit() {
		this.items = this.quickListService.getList(this.parentKey, this.type).valueChanges();
	}

	delete(key: string) {
		this.quickListService.delete(key, this.parentKey, this.type);
	}
}
