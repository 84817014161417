import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DiscussionComponent } from "app/discussion/discussion/discussion.component";
import { AuthGuard } from "app/app.guard";
import { DiscussionItemModalComponent } from "app/discussion/discussion-item-modal/discussion-item-modal.component"

const routes: Routes = [
	{ path: 'neighborhood/discussion', component: DiscussionComponent, canActivate: [AuthGuard],
		children: [
			{				
				path: 'view/:key/:tag/:query/:queryDate',
				component: DiscussionItemModalComponent,
				outlet: 'modal'
			}
	] }

			
];

@NgModule({ 
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiscussionRoutingModule { }
