import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorialRoutingModule } from './tutorial-routing.module';
import { TutorialSigninComponent } from './tutorial-signin/tutorial-signin.component';
import { TutorialForgotPasswordComponent } from './tutorial-forgot-password/tutorial-forgot-password.component';
import { TutorialAlertsComponent } from './tutorial-alerts/tutorial-alerts.component';
import { TutorialCalendarComponent } from './tutorial-calendar/tutorial-calendar.component';
import { TutorialDiscussionBoardComponent } from './tutorial-discussion-board/tutorial-discussion-board.component';
import { TutorialMapsComponent } from './tutorial-maps/tutorial-maps.component';
import { TutorialMembersComponent } from './tutorial-members/tutorial-members.component';
import { TutorialProfileComponent } from './tutorial-profile/tutorial-profile.component';
import { TutorialTagsComponent } from './tutorial-tags/tutorial-tags.component';
import { TutorialMessagesComponent } from './tutorial-messages/tutorial-messages.component';
import { TutorialNotificationsComponent } from './tutorial-notifications/tutorial-notifications.component';
import { TutorialProfileSetupComponent } from './tutorial-profile-setup/tutorial-profile-setup.component';
import { TutorialTestAlertComponent } from './tutorial-test-alert/tutorial-test-alert.component';
import { TutorialStartGroupComponent } from './tutorial-start-group/tutorial-start-group.component';
import { TutorialSendAlertComponent } from './tutorial-send-alert/tutorial-send-alert.component';
import { TutorialWelcomeComponent } from './tutorial-welcome/tutorial-welcome.component';
import { TutorialChangeGroupComponent } from './tutorial-change-group/tutorial-change-group.component';
import { TutorialRingToneComponent } from './tutorial-ring-tone/tutorial-ring-tone.component';

@NgModule({
  imports: [
    CommonModule,
    TutorialRoutingModule
  ],
  exports: [TutorialRoutingModule],
  declarations: [TutorialSigninComponent,
    TutorialForgotPasswordComponent,
    TutorialAlertsComponent,
    TutorialCalendarComponent,
    TutorialDiscussionBoardComponent,
    TutorialMapsComponent,
    TutorialMembersComponent,
    TutorialProfileComponent,
    TutorialTagsComponent,
    TutorialMessagesComponent,
    TutorialNotificationsComponent,
    TutorialProfileSetupComponent,
    TutorialTestAlertComponent,
    TutorialStartGroupComponent,
    TutorialSendAlertComponent,
    TutorialWelcomeComponent,
    TutorialChangeGroupComponent,
    TutorialRingToneComponent
]
})
export class TutorialModule { }
