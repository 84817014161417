import { SecurityContext } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { truncateSync } from 'fs';

@Pipe({ name: 'safeURL' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string): SafeResourceUrl {
    debugger;    
    var safeResource = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return safeResource;    
  }
} 
