import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from 'querystring';

@Pipe({name: 'phoneNumberFormat'})
export class PhoneNumberFormatPipe implements PipeTransform {
  transform(tel: string): string {
           
      var arrTel = ['(', ' ', ' ', ' ', ')', ' ', ' ', ' ', ' ', '-', ' ', ' ', ' ', ' '];
      // format the number into "(nnn) nnn-nnnn" format
            
      // strip out the symbols
      tel = tel.replace('(', '');
      tel = tel.replace(')', '');
      tel = tel.replace(' ', '');
      tel = tel.replace(new RegExp("-", "g"), '');  // replace all dashes with an empty space, e.g.,
                                                    // when loading up in the update profile component
      tel = tel.trim();

      var i;
      var retTel = "";
      var iNumberCount = 1;
      if (tel.length === 0) {  // return the empty format "(   )    -     "
        // var j;        
        // for (j = 0; j < arrTel.length; j++) {            
        //   retTel += arrTel[j];
        // }
      }
      else {
        for (i = 0; i < tel.length; i++)         
        {        
            // put number in its proper position in the array
            arrTel[iNumberCount] = tel[i];
            // increment to next position according to pattern
            if (iNumberCount == 1)
              iNumberCount++;
            else if (iNumberCount == 2)
              iNumberCount++;
            else if (iNumberCount == 3)
              iNumberCount += 3;
            else if (iNumberCount == 6)
              iNumberCount++;
            else if (iNumberCount == 7)
              iNumberCount++;
            else if (iNumberCount == 8)
              iNumberCount += 2;
            else if (iNumberCount == 10)
              iNumberCount++;
            else if (iNumberCount == 11)
              iNumberCount++;
            else if (iNumberCount == 12)
              iNumberCount++;
            else  // no more slots
              break;
        }
              
        var j;        
        for (j = 0; j < arrTel.length; j++) {
          if (j == 1 || 
              j == 2 ||
              j == 3 ||
              j == 6 ||
              j == 7 || 
              j == 8 ||
              j == 10 ||
              j == 11 ||
              j == 12 ||
              j == 13 ) {
            if (arrTel[j] == ' ')
              break;
          }          
          retTel += arrTel[j];
        }
      }
          
      return retTel;
  }

  
}