import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tutorial-ring-tone',
  templateUrl: './tutorial-ring-tone.component.html',
  styleUrls: ['./tutorial-ring-tone.component.css']
})
export class TutorialRingToneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
