import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersComponent } from 'app/neighborhood/members/members.component';
import { MemberViewComponent } from 'app/neighborhood/member-view/member-view.component';
import { AuthGuard } from 'app/app.guard';
import { NeighborhoodCreateComponent } from 'app/neighborhood/neighborhood-create/neighborhood-create.component';
import { CheckoutComponent } from 'app/neighborhood/checkout/checkout.component';

const routes: Routes = [
	{ path: 'neighborhood/members', component: MembersComponent, canActivate: [AuthGuard] },
	{ path: 'neighborhood/create', component: NeighborhoodCreateComponent, canActivate: [AuthGuard] },
	{ path: 'neighborhood/members/:key', component: MemberViewComponent, canActivate: [AuthGuard] },
	{ path: 'neighborhood/checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
	{ path: 'neighborhood/checkout/:session_id', component: CheckoutComponent, canActivate: [AuthGuard] }	
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NeighborhoodRoutingModule { }
