import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from "app/shared/modal/modal.component";
import { AppEngineService } from "app/app-engine.service";
import { AccountService } from './core/services/account.service';
import { SitesService } from './core/services/sites.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	@ViewChild('carouselModal') carouselModal: ModalComponent;

	run() {
	//	this.as.fixImage();
	}
	constructor(private router: Router, private as: SitesService) {
		//	
		//imagesService.showingAlbumCarousel.subscribe((albumKey: string) => {
		//	this.carouselModal.show();
		//});
	}
}
