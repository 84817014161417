import { Injectable } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from 'firebase';

@Injectable()
export class QuickListService {

	private _quickListRef = this.db.database.ref('quick-list');

	constructor(protected db: AngularFireDatabase,
		protected afAuth: AngularFireAuth) { }

	getList(key: string, type: string) {
		return this.db.list(this._quickListRef.child(key).child(type));
	}

	add(key: string, type: string, title: string, description: string, albumKey: string) {
		return this.db.list(this._quickListRef.child(key).child(type))
			.push({
				title: title,
				description: description,
				albumKey: albumKey
			});
	}

	delete(key: string, parentKey: string, type: string) {
		return this.db.list(this._quickListRef.child(key).child(type).child(key)).remove();
	}
}
