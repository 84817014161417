
import {take} from 'rxjs/operators';
import { AccountService } from 'app/core/services/account.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable()
export class AlbumsService {

	private _albumsRef = this.db.database.ref('albums');
	private _userAlbumsRef = this.db.database.ref('user-albums');

	constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth,
		private accountService: AccountService) { }

	getAlbum(key: string) {
		return this.db.object(this._albumsRef.child(key));
	}

	getAlbumFiles(key: string) {
		return this.db.list(this._albumsRef.child(key).child('files'));
	}

	getAlbumImages(key: string) {

		return this.db.list(this._albumsRef.child(key).child('images'));
	}

	getAlbumVideos(key: string) {
		return this.db.list(this._albumsRef.child(key).child('videos'));
	}

	removeItemFromAlbum(ref: string) {
		//TODO: This probably is creating a race condition with gcs. It might finish after
		//      an image has been resized
		this.db.object(ref).remove();
	}

	addFilesToAlbum(albumKey: string, files: FileList) {
		let fileTasks: { task: firebase.storage.UploadTask, fileRef: firebase.database.ThenableReference, file: File }[] = [];
		let storage = firebase.storage();
		let storageRef = storage.ref();

		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let path = file.type.indexOf('image') > -1 ? 'images' : (file.type.indexOf('video') > -1 ? 'videos' : 'files')
			let fileStorageRef = storageRef.child(albumKey).child(file.name);
			let fileRef = this.db.list(this._albumsRef.child(albumKey).child(path)).push({
				filename: file.name,
				type: file.type,
				size: file.size
			});
			let fileUploadTask = fileStorageRef.put(file,
				{
					customMetadata:
					{
						updatePath: `albums/${albumKey}/images/${fileRef.key}`,
						fileKey: fileRef.key
					}, cacheControl: 'max-age=31536000', contentType: file.type
				});

			fileTasks.push({
				file: file,
				fileRef: fileRef,
				task: fileUploadTask
			});

			fileUploadTask.then(snapshot => {
				snapshot.ref.getDownloadURL()
					.then(value => {
						this.db.object(this._albumsRef.child(albumKey).child(path).child(fileRef.key))
							.update({
								downloadURL: value,
								downloadURLOriginal: value
							});
					});
			});
		}

		return fileTasks;
	}

	create(name: string = null, files: FileList): {
		albumRef: firebase.database.ThenableReference,
		fileTasks: {
			task: firebase.storage.UploadTask,
			file: File
		}[]
	} {
		let albumRef = this.db.list(this._albumsRef).push({
			createdAt: firebase.database.ServerValue.TIMESTAMP,
			createdBy: this.afAuth.auth.currentUser.uid,
			name: name
		});

		this.accountService.currentSiteKey.pipe(
			take(1))
			.subscribe(siteKey => {
				this.db.object(this._userAlbumsRef.child(this.afAuth.auth.currentUser.uid).child(siteKey).child(albumRef.key)).set(true);
			});

		return {
			albumRef: albumRef,
			fileTasks: this.addFilesToAlbum(albumRef.key, files)
		};
	}
}
