import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsService } from "app/notifications/notifications.service";

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	providers: [NotificationsService]
})
export class NotificationsModule { }
