import { Directive, OnInit, ElementRef, Input, OnChanges } from '@angular/core';
import { SitesService } from "app/core/services/sites.service";
import { AccountService } from "app/core/services/account.service";

@Directive({
	selector: '[neighborhoodDisplayName]'
})
export class DisplayNameDirective implements OnInit, OnChanges {
keyValue:any;
	@Input('neighborhoodDisplayName') key: string;

	constructor(private accountService: AccountService,
		private sitesService: SitesService,
		private elementRef: ElementRef) { }

	ngOnInit() {
		

	}

	ngOnChanges() {
		this.keyValue=this.key;
		
		if (this.key && this.key==this.keyValue) {
			this.sitesService.getGroup(this.key)
				.valueChanges()
				.subscribe((site: any) => {
					
					//console.log("name", site.name)
					if(site.name){
						this.elementRef.nativeElement.innerHTML = site.name;
					}
					
				});
		} else {
		
			// this.accountService.currentSiteKey.subscribe(siteKey => {
			// 	this.sitesService.getGroup(siteKey)
			// 		.valueChanges()
			// 		.subscribe((site: any) => {
			// 			
			// 			//console.log("name", site.name)
			// 			if(site.name){
			// 				this.elementRef.nativeElement.innerHTML = site.name;
			// 			}
						
			// 		});
			// });
		}

	}

}
