import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-member-view',
	templateUrl: './member-view.component.html',
	styleUrls: ['./member-view.component.css']
})
export class MemberViewComponent implements OnInit {

	key: any;
    query = ''; 
	constructor(activatedRoute: ActivatedRoute) {
		activatedRoute.params.subscribe(params => this.key = params.key);
	}

	ngOnInit() {
	}

}
