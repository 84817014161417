import { AlbumsService } from './../../core/services/albums.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-album-cover',
    templateUrl: './album-cover.component.html',
    styleUrls: ['./album-cover.component.css']
})
export class AlbumCoverComponent implements OnInit, OnChanges {

    @Input() albumKey: string

    album: any;
    constructor(private albumsService: AlbumsService, public sanitizer: DomSanitizer) { }

    ngOnInit() {}
    

    ngOnChanges() {
        if (this.albumKey)
            this.album = this.albumsService.getAlbum(this.albumKey).valueChanges();
    }

    albumHasImages(album: any) {
        return !!album.images
    }

    getAlbumCoverImage(album) {
        let keys = Object.keys(album.images);
        if (keys.length) {
            //console.log(album.images[keys[0]].downloadURL)
            return album.images[keys[0]].downloadURL;
        }
    }

    getAlbumColors(album) {
        let keys = Object.keys(album.images);
        if (keys.length) {
            return `#${album.images[keys[0]].colors[1]}`;
        }
    }

    getAlbumImageCount(album) {
        return Object.keys(album.images).length;
    }

    getVideos(album) {
        if (album.videos) {
            return Object.keys(album.videos).map(function (key) { return album.videos[key]; })
        }
        else {
            return [];
        }
    }

    getFiles(album) {
        if (album.files) {
            return Object.keys(album.files).map(function (key) { return album.files[key]; })
        }
        else {
            return [];
        }
    }
}
