import { Component, OnInit, ViewChild } from '@angular/core';
import { AppEngineService } from "app/app-engine.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalComponent } from "app/shared/modal/modal.component";

@Component({
	selector: 'app-validate-email',
	templateUrl: './validate-email.component.html',
	styleUrls: ['./validate-email.component.css']
})
export class ValidateEmailComponent implements OnInit {
	@ViewChild('errorModal') errorModal: ModalComponent;

	email = '';
	password = '';
	confirm = '';
	token: string;
	siteKey: string;
	errorMessage: string;

	constructor(private router: Router, private route: ActivatedRoute, private appEngine: AppEngineService) { }

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.email = params.email;
			this.token = params.token;
			this.siteKey = params.siteKey;
		});

		this.appEngine.authState.subscribe(user => {
			if (user) {
				//this.router.navigate(['complete-signup']);
			}
		});
	}

	submit() {
		if (this.email.trim().length > 0 &&
			this.password.trim().length > 0 &&
			this.confirm.trim().length > 0 &&
			this.confirm.trim() === this.password.trim()) {
			this.appEngine.auth.signInWithEmailAndPassword(this.email, this.password)
				.then(user => {
					this.router.navigate(['complete-signup']);
				}, (error: any) => {
					switch (error.code) {
						case 'auth/user-not-found':
							this.appEngine.auth.createUserWithEmailAndPassword(this.email, this.password)
								.then(user => {
									this.appEngine.auth.signInWithEmailAndPassword(this.email, this.password)
										.then(user => {
											this.appEngine.currentUser.initialize(this.email, this.siteKey, this.token);
											this.router.navigate(['complete-signup']);
										});
								}, error => {
									this.errorMessage = error.message;
									this.errorModal.show();
								});
							break;
						default:
							this.errorMessage = error.message;
							this.errorModal.show();
					}
				})
		} else {
			if(this.password != this.confirm) {
				this.errorMessage = 'Passwords do not match';
			} else if(this.password.trim().length === 0) {
				this.errorMessage = 'Password cannot be blank';
			}
				this.errorModal.show();
		}
		//this.appEngine.currentUser.completeSiteSignup(this.token, this.siteKey, this.email, this.password);
	}
}
