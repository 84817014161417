import { ResetPasswordSentComponent } from './reset-password-sent/reset-password-sent.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewUserComponent } from './new-user/new-user.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdateProfileComponent } from "app/account/update-profile/update-profile.component";
import { AuthGuard } from "app/app.guard";
import { LoginComponent } from "app/account/login/login.component";
import { NeighborhoodCreateComponent } from 'app/neighborhood/neighborhood-create/neighborhood-create.component';
import { CompleteProfileComponent } from "app/account/complete-profile/complete-profile.component";

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'join/:code', component: LoginComponent},
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'reset-password/:email', component: ResetPasswordSentComponent },
	{ path: 'new-user', component: NewUserComponent },
	{ path: 'new/join/:code', component: NewUserComponent },	
	{ path: 'new/join', component: NewUserComponent },
	{ path: 'edit-profile', component: UpdateProfileComponent, canActivate: [AuthGuard] },
	{ path: 'neighborhood/create', component: NeighborhoodCreateComponent, canActivate: [AuthGuard]  },
	{ path: 'complete-profile', component: CompleteProfileComponent, canActivate: [AuthGuard]}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccountRoutingModule { }
