import { Directive, Input, OnChanges, OnDestroy, ElementRef } from '@angular/core';
import { AppEngineService } from "app/app-engine.service";

@Directive({
	selector: '[displayName]'
})
export class DisplayNameDirective implements OnChanges, OnDestroy {

	@Input('displayName') uid: string;

	subscription: any;

	constructor(private appEngine: AppEngineService, private elementRef: ElementRef) { }

	ngOnChanges() {
		/*this.subscription = this.appEngine.people.getUserDetails(this.uid)
			.valueChanges()
			.subscribe((details: any) => {
			
				this.elementRef.nativeElement.innerHTML = details.name;
			});*/
	}

	ngOnDestroy() {
		//this.subscription.unsubscribe();
	}
}
