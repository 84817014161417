import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-neighborhood-edit',
  templateUrl: './neighborhood-edit.component.html',
  styleUrls: ['./neighborhood-edit.component.css']
})
export class NeighborhoodEditComponent implements OnInit {
  @Input() currentSite;
  @Input() backgroundImage;
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit() {
    //;
  }
}
