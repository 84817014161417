import { Component, OnInit, isDevMode } from '@angular/core';
import { AppEngineService } from "app/app-engine.service";
import { CheckOutService} from 'app/core/services/checkout.service';
import { Router } from '@angular/router';
import { SitesService } from "app/core/services/sites.service";
import { AngularFireAuth } from "angularfire2/auth";
import { Title } from '@angular/platform-browser';
import { AccountService } from "app/core/services/account.service";


@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})
export class JoinComponent implements OnInit {

  loggedin: boolean = false;
  groupObj = {count:0};
  subscriptionObj = {status:''};
  communityBoughtObj = {count: 0};
	communityObj = {status:''};
  communityGroupObj = {count: 0};
  website = 'https://dev.wissle.com'
  
  constructor(private appEngine: AppEngineService,
              private checkoutService: CheckOutService,
              private router: Router,
              private sitesService: SitesService,
              private afAuth: AngularFireAuth,
              private titleService: Title,
              private accountService: AccountService) { 
                titleService.setTitle('Wissle Join');
              }

  ngOnInit() {
    this.appEngine.auth.onAuthStateChanged((user) => {
			if (user) {        
        this.loggedin = true;        
        this.sitesService.getUserStatus(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj);        
			} else {        
        this.loggedin = false;        
			}
		  });
  }

  subscribed(){
		alert('You have reached the maximum level of this subscription plan!');
  }
  
  subscribeEssential()
  {
    if (!this.loggedin)  // redirect to login
    {
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'essential' }});
    }	
    else
    {
      this.router.navigate(['/neighborhood/create']);
    }
  }

  subscribePlus() 
	{	
    if (!this.loggedin)  // redirect to login
    {
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'plus' }});
    }	
    else
    {
      if (this.groupObj.count < 2)  // go straight to stripe payment
      {                  
        this.checkoutService.setSubscription('plus');	                                                                  
        this.accountService.subscribeTo('price_1I3wY3DZWXSMJjj8rti9dRRL', this.website);                      
      }      
    }
		
  }
  
  nextPremium() {
    if (!this.loggedin) { // redirect to login
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'premium' }});
    }	
    else {
      this.router.navigate(['/neighborhood/create'], { queryParams: {communityType: 'Group', subscriptionType: 'premium' }});
    }
  }

  subscribePremium() 
	{	
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'premium' }});
    }	
    else {
      if (this.groupObj.count < 6) { // go straight to stripe payment                             
        this.checkoutService.setSubscription('premium');	                                                                  
        this.accountService.subscribeTo('price_1I3wagDZWXSMJjj8QeDat0dH', this.website);                       
      }      
    }		
  }

  subscribeSmall() 
	{		    
    if (!this.loggedin) { // redirect to login          
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'small' }});
    }	
    else {
      if (this.communityGroupObj.count < this.communityBoughtObj.count) {
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('small');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);
        this.accountService.subscribeTo('price_1I49G6DZWXSMJjj8IEItYljo', this.website);	        
      }      
    }		
	}

	subscribeMedium() 
	{	
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'medium' }});
    }	
    else {		
      if (this.communityGroupObj.count < this.communityBoughtObj.count) {
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('medium');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		
        this.accountService.subscribeTo('price_1I49GgDZWXSMJjj895FB2TEm', this.website);        
      }
    }
	}

	subscribeLarge() 
	{		
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Community', subscriptionType: 'large' }});
    }	
    else {	
      if (this.communityGroupObj.count < this.communityBoughtObj.count) {
        this.router.navigate(['/neighborhood/create']);
      }
      else {
        this.checkoutService.setSubscription('large');	
        this.checkoutService.setSubscriptionsBought(this.communityBoughtObj.count);		
        this.accountService.subscribeTo('price_1I49H8DZWXSMJjj806IV5g46', this.website);        
      }
    }
	}

}
