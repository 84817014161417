import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.css']
})
@NgModule({
    imports: [
        CommonModule,
    ]
})
export class ThankYouComponent implements OnInit {


    constructor() { }

    ngOnInit() {
    }


}
