import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';



@Component({
	selector: 'app-slides-alert',
	templateUrl: './slides-alert.component.html',
	styleUrls: ['./slides-alert.component.css']
})
export class SlidesAlertComponent implements OnInit {

	public modalRef: any;
	public config = {
		animated: true,
		keyboard: true,
		backdrop: true,
		interval: false,
		noWrap: true,
		ignoreBackdropClick: false
	};
	public myInterval: number = 10000000;
	constructor(private modalService: BsModalService) { }

	public openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, this.config);
	}

	public openModalWithClass(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, Object.assign({}, this.config, { class: 'sliderModal' }));
	}
	ngOnInit() {
	}

}
