import { Component, OnInit, isDevMode } from '@angular/core';
import { AppEngineService } from "app/app-engine.service";
import { CheckOutService} from 'app/core/services/checkout.service';
import { Router } from '@angular/router';
import { SitesService } from "app/core/services/sites.service";
import { AngularFireAuth } from "angularfire2/auth";
import { Title } from '@angular/platform-browser';
import { AccountService } from "app/core/services/account.service";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  loggedin: boolean = false;
  groupObj = {count:0};
  subscriptionObj = {status:''};
  website = 'https://dev.wissle.com';


  constructor(private appEngine: AppEngineService,
    private checkoutService: CheckOutService,
    private router: Router,
    private sitesService: SitesService,
    private afAuth: AngularFireAuth,
    private titleService: Title,
    private accountService: AccountService) {
      titleService.setTitle('Wissle About');
     }

  ngOnInit() {
    this.appEngine.auth.onAuthStateChanged((user) => {
			if (user) {        
        this.loggedin = true;        
        this.sitesService.getUserStatus(this.afAuth.auth.currentUser.uid, this.subscriptionObj, this.groupObj);        
			} else {        
        this.loggedin = false;        
			}
      });
      
      //console.log('isdevmode', isDevMode());
      if (isDevMode())
        this.website = 'https://dev.wissle.com';
  }
  
  subscribed(){
		alert('You have reached the maximum level of this subscription plan!');
  }
  
  subscribeEssential()
  {    
    if (!this.loggedin)  // redirect to login
    {
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'essential' }});
    }	
    else
    {
      this.router.navigate(['/neighborhood/create']);
    }
  }

  async subscribePlus() {	    
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'plus' }});
    }	
    else  {
      if (this.groupObj.count < 2) { // go straight to stripe payment  
        this.checkoutService.setSubscription('plus');	                                                                  
        this.accountService.subscribeTo('price_1I3wY3DZWXSMJjj8rti9dRRL', this.website);                                      
      }      
    }		
  }
  
  nextPremium() {
    if (!this.loggedin) { // redirect to login
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'premium' }});
    }	
    else {
      this.router.navigate(['/neighborhood/create'], { queryParams: {communityType: 'Group', subscriptionType: 'premium' }});
    }
  }

  async subscribePremium() {	    
    if (!this.loggedin) { // redirect to login    
      this.router.navigate(['/new-user'], { queryParams: { forwardUrl: '/neighborhood/create', communityType: 'Group', subscriptionType: 'premium' }});
    }	
    else {
      debugger;
      if (this.groupObj.count < 6) { // go straight to stripe payment     
        this.checkoutService.setSubscription('premium');	                                                                  
        this.accountService.subscribeTo('price_1I3wagDZWXSMJjj8QeDat0dH', this.website);        
      }      
    }
		
	}
}
