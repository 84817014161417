import { Component, OnInit } from '@angular/core';
import { ContactService } from "app/core/services/contact.service";

@Component({
    selector: 'app-contact-join',
    templateUrl: './contact-join.component.html',
    styleUrls: ['./contact-join.component.css']
})
export class ContactJoinComponent implements OnInit {

    memberCount = '';
    hoa = '';
    address = '';
    email = '';
    name = '';
    description = '';
    phone = ''

    constructor(private contactService: ContactService) { }

    ngOnInit() {
    }

    submit() {
        this.contactService.join(this.email, this.name, this.phone, this.description, this.hoa, this.memberCount, this.address)
            .then(() => {
                this.email = '';
                this.name = '';
                this.description = '';
                this.phone = '';
                this.hoa = '';
                this.address = '';
                this.memberCount = '';
            })
    }

}
