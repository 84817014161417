import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidesAlertComponent } from 'app/slides/slides-alert/slides-alert.component';
import { CarouselModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap';


@NgModule({
  imports: [
    CommonModule,CarouselModule.forRoot(),ModalModule.forRoot()
  ],
  declarations: [SlidesAlertComponent],
  exports: [SlidesAlertComponent]
})
export class SlidesModule { }

