import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CommentsService } from "app/comments/comments.service";

@Component({
	selector: 'app-comment-item-list',
	templateUrl: './comment-item-list.component.html',
	styleUrls: ['./comment-item-list.component.css']
})
export class CommentItemListComponent implements OnInit, OnChanges {

	@Input() parentKey: string;
  @Input() source: string;  // helps load the comments in ascending or descending order

	comments: any;
	// obj: {createdBy: string, createdAt: number};
	// objArray = [];

	constructor(private commentsService: CommentsService) { }

	ngOnInit() {
		var source = this.source;
	}

	ngOnChanges() {
		if (this.parentKey)
		{
			
			// if (this.parentKey == '-MHEz1GAzQwH6_OoAPdB')
			// {
			// 	debugger;
				
			// 	let promises = Promise.all(
			// 		[
			// 			this.commentsService.getUsersWhoHaveCommented(this.parentKey, this.obj, this.objArray)
			// 		]
			// 	);
		
			// 	promises.then(() => {	
			// 		debugger;																								
			// 	}, error => {					
			// 		alert(error)
			// 	});	

			// }
			this.comments = this.commentsService.getCommentKeys(this.parentKey, this.source);
		}
			
	}

}
