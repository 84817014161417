import { Component, OnInit } from '@angular/core';
import { Routes, Router } from "@angular/router";
import { AccountService } from "app/core/services/account.service";

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

	constructor(private routes: Router, private accounService: AccountService) { }

	ngOnInit() {
		this.accounService.isAuthenticated.subscribe(isAuthenticated => {
			if (isAuthenticated)
				this.routes.navigate(['/neighborhood', 'alerts'])
			else
				this.routes.navigate(['/marketing','about'])
		})
	}

}
