import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-cameras-item-modal',
    templateUrl: './cameras-item-modal.component.html',
    styleUrls: ['./cameras-item-modal.component.css']
})
export class CamerasItemModalComponent implements OnInit {

    key: string;

    constructor(private activatedRoute: ActivatedRoute, private titleService: Title) {
        titleService.setTitle('Wissle Cameras Modal');
     }

    ngOnInit() {
        this.activatedRoute.params
			.subscribe(params => {
				this.key = params.key;
			});
    }

}
 